import {CardCompact} from 'components/Main/CardCompact';
import {TripleLayout} from 'components/Main/Layout/Triple';
import {buildMessage} from 'lib/intl';
import React from 'react';
import {useIntl} from 'react-intl';

/* eslint-disable max-len */
const messages = [
  'Найдем товар по вашему запросу и получим лучшее предложение от поставщика или возьмем на себя работу с вашим поставщиком',
  'Перед закупкой вы сможете убедиться в качестве товара: пришлем фото/видео или привезем образцы',
  'Организуем доставку из Китая до вашего склада с оформлением всех необходимых таможенных документов',
];
/* eslint-enable max-len */

type Props = {
  gridArea?: string;
};

export function Cards({gridArea}: Props): React.ReactElement {
  const intl = useIntl();

  return (
    <TripleLayout
      blockA={
        <CardCompact
          border='red'
          color='black-deep'
          illustration='/illustrations/main2/sectionWelcome/figure_1.svg'
          title={buildMessage(intl, messages[0])}
        />
      }
      blockB={
        <CardCompact
          border='red'
          color='black-deep'
          illustration='/illustrations/main2/sectionWelcome/figure_2.svg'
          title={buildMessage(intl, messages[1])}
        />
      }
      blockC={
        <CardCompact
          border='red'
          color='black-deep'
          illustration='/illustrations/main2/sectionWelcome/figure_3.svg'
          title={buildMessage(intl, messages[2])}
        />
      }
      gridArea={gridArea}
    />
  );
}
