import {Card} from 'components/Main/Card';
import {Promotion} from 'lib/promotion/types';
import React from 'react';
import {defineMessages, useIntl} from 'react-intl';
import {ProductsSelection} from '../../components/ProductsSelection';
import styles from './index.module.scss';

const messages = defineMessages({
  subtitle: {
    defaultMessage: 'View all-inclusive prices with production, customs, and delivery included',
    description: 'Self service main page. Block with groups of products: subtitle',
  },
  title: {
    defaultMessage: 'See final prices',
    description: 'Self service main page. Block with groups of products: title',
  },
});

type Props = {
  promotions: Promotion[];
};

export function BlockProducts({promotions}: Props): React.ReactElement {
  const intl = useIntl();

  return (
    <div className={styles.container}>
      <Card color='black' padding='xs' rounded='m'>
        <div className={styles.title}>{intl.formatMessage(messages.title)}</div>
        <div className={styles.subtitle}>{intl.formatMessage(messages.subtitle)}</div>
      </Card>
      <div className={styles.content}>
        {promotions.map((promotion) => (
          <ProductsSelection
            items={promotion.items}
            key={promotion.promotionId}
            position={promotion.title || ''}
            promotionId={promotion.promotionId}
            title={promotion.title}
          />
        ))}
      </div>
    </div>
  );
}
