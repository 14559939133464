import {ExtMessageDescriptor, MessageValues} from 'lib/intl/types';

export enum DataType {
  ORDERED_LIST = 'orderedList',
  PARAGRAPH = 'paragraph',
  UNORDERED_LIST = 'unorderedList',
}

export type ParagraphColor = 'accent' | 'secondary';
export type ParagraphState = {
  options?: {
    bold?: boolean;
    color?: ParagraphColor;
    values?: MessageValues;
  };
  type: DataType.PARAGRAPH;
  value: ExtMessageDescriptor;
};

export type ListStyleType = 'disc' | 'decimal' | 'none';
export type UnorderedListState = {
  options?: {
    label?: ExtMessageDescriptor;
    listStyleType?: ListStyleType;
    values?: MessageValues;
  };
  type: DataType.UNORDERED_LIST;
  value: ExtMessageDescriptor[];
};

export type OrderedListState = {
  options?: {
    label?: ExtMessageDescriptor;
    start?: number;
    values?: MessageValues;
  };
  type: DataType.ORDERED_LIST;
  value: ExtMessageDescriptor[];
};

export type DataItem = ParagraphState | OrderedListState | UnorderedListState;
