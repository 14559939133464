import cn from 'classnames';
import {Carousel} from 'components/Carousel';
import {ProductLite} from 'components/ProductLite';
import {isContentListProductItem} from 'lib/contentList';
import {ContentList, ContentListProductItem} from 'lib/contentList/types';
import React, {useMemo} from 'react';
import styles from './index.module.scss';

const DEFAULT_ITEMS: ContentList = [];

export type Props = {
  cardSize?: 'adaptive' | 'small';
  carouselType?: 'carousel' | 'list';
  items: ContentList;
  position: string;
  productLikeCard?: boolean;
  promotionId?: string;
};

export function Products({
  position,
  promotionId,
  items = DEFAULT_ITEMS,
  cardSize = 'adaptive',
  carouselType = 'list',
  productLikeCard = false,
}: Props): React.ReactElement {
  const products = useMemo<ContentListProductItem[]>(() => {
    return items.filter((item): item is ContentListProductItem => {
      return isContentListProductItem(item);
    });
  }, [items]);

  const content = products.map((product, index) => (
    <div className={cn(styles.product, {[styles.card]: productLikeCard})} key={product.id}>
      <ProductLite
        alwaysShowPrice
        index={index}
        noRating
        position={position}
        product={product.content}
        promotionId={promotionId}
      />
    </div>
  ));

  return (
    <div className={cn(styles.container, styles[cardSize], {[styles.carousel]: carouselType === 'carousel'})}>
      <div className={cn(styles.mobile)}>
        <Carousel>{content}</Carousel>
      </div>
      <div className={styles.desktop}>{carouselType === 'carousel' ? <Carousel>{content}</Carousel> : content}</div>
    </div>
  );
}
