import cn from 'classnames';
import {YouTubeVideoPlayer} from 'components/Main/YouTubeVideoPlayer';
import LeftArrowIcon from 'icons/long-arrow-left.svg';
import RightArrowIcon from 'icons/long-arrow-right.svg';
import React, {useCallback, useState} from 'react';
import {Button} from 'uikit/Button';
import styles from './index.module.scss';

type Case = {
  comment: string;
  employee: string;
  job: string;
  video: string;
};

export type Props = {
  cases: Case[];
  title: string;
};

export const Cases = ({title, cases}: Props) => {
  const [currentCase, setCurrentCase] = useState<number>(0);

  const handleNextCase = useCallback(() => {
    setCurrentCase((prev: number) => (prev < cases.length - 1 ? prev + 1 : 0));
  }, [setCurrentCase, cases.length]);

  const handlePrevCase = useCallback(() => {
    setCurrentCase((prev: number) => (prev > 0 ? prev - 1 : cases.length - 1));
  }, [setCurrentCase, cases.length]);

  return (
    <div className={styles.container}>
      <span className={styles.title}>{title}</span>
      <div className={styles.cases}>
        {cases.map((item: Case, index: number) => (
          <div
            className={cn(styles.content, {[styles.visible]: currentCase === index})}
            key={index}
            style={{transform: `translate(-${100 * currentCase}%)`}}
          >
            <div className={styles.leftSide}>
              <div className={styles.text}>
                <span className={styles.description}>{item.comment}</span>
                <div className={styles.jobInfo}>
                  <span className={styles.employee}>{item.employee}</span>
                  <span className={styles.description}>{item.job}</span>
                </div>
              </div>
              {cases.length > 1 && (
                <div className={styles.buttons}>
                  <Button
                    color='outline-white'
                    iconLeft={<LeftArrowIcon />}
                    onClick={handlePrevCase}
                    shape='circle'
                    size='large'
                  />
                  <Button
                    color='outline-white'
                    iconLeft={<RightArrowIcon />}
                    onClick={handleNextCase}
                    shape='circle'
                    size='large'
                  />
                </div>
              )}
            </div>
            <YouTubeVideoPlayer className={styles.video} src={item.video} startImmediately />
          </div>
        ))}
      </div>
    </div>
  );
};
