import {defineMessages} from 'react-intl';

export const growBusinessMessages = defineMessages({
  button: {
    defaultMessage: 'Talk to a specialist',
    description: '[button] Grow Business Main',
  },
  step1Description: {
    defaultMessage: 'Our experts select relevant products and our algorithms find the best options for your search.',
    description: '[description] Grow Business step. Main',
  },
  step1Label: {
    defaultMessage: 'Personalized recommendations',
    description: '[label] Grow Business step. Main',
  },
  step2Description: {
    defaultMessage:
      'Receive product samples to assess quality and ensure they meet your needs. We arrange delivery in a convenient way for you – directly to your office or via video message sent to your email.',
    description: '[description] Grow Business step. Main',
  },
  step2Label: {
    defaultMessage: 'Check quality with a sample',
    description: '[label] Grow Business step. Main',
  },
  step3Description: {
    defaultMessage:
      "Upload an image of the product you're looking for, and we'll quickly find similar offers from our suppliers.",
    description: '[description] Grow Business step. Main',
  },
  step3Label: {
    defaultMessage: 'Product search by image',
    description: '[label] Grow Business step. Main',
  },
  step4Description: {
    defaultMessage:
      'We work on customized projects, and our suppliers are equipped to tailor products according to your needs—from product development and branding to packaging customization, barcode printing, and translated manuals.',
    description: '[description] Grow Business step. Main',
  },
  step4Label: {
    defaultMessage: 'Customization',
    description: '[label] Grow Business step. Main',
  },
  step5Description: {
    defaultMessage:
      'We partner with financial institutions that offer credit solutions for those looking to import and grow their businesses but need financial support to make the operation possible. Our partners conduct credit analysis to identify the best financing package for your company.',
    description: '[description] Grow Business step. Main',
  },
  step5Label: {
    defaultMessage: 'Financing for imports',
    description: '[label] Grow Business step. Main',
  },
  step6Description: {
    defaultMessage:
      'RADAR Siscomex is a regulatory system by the Federal Revenue Service that allows Brazilian companies to participate in international trade, including import operations. JoomPro offers support and guidance throughout the entire process of obtaining your RADAR Siscomex authorization.',
    description: '[description] Grow Business step. Main',
  },
  step6Label: {
    defaultMessage: 'Support for obtaining RADAR Siscomex authorization',
    description: '[label] Grow Business step. Main',
  },
  title: {
    defaultMessage: 'Grow your business with JoomPro',
    description: '[title] Grow Business Main',
  },
});

export const casesMessages = defineMessages({
  button: {
    defaultMessage: 'Learn more',
    description: '[button] About Main',
  },
  title: {
    defaultMessage: 'What people are saying about JoomPro',
    description: '[title] Cases Main',
  },
});

export const aboutMessages = defineMessages({
  button: {
    defaultMessage: 'Learn more',
    description: '[button] About Main',
  },
  description: {
    defaultMessage:
      "JoomPro is an import management company specializing in the Chinese market. It is part of the Joom Group, and its mission is to help companies import simply and securely, boosting their businesses with highly profitable products. To achieve this, it offers an end-to-end service that covers all stages of the import process, from sourcing suppliers to delivering goods to clients' warehouses.",
    description: 'HeroSection Main description',
  },
  title: {
    defaultMessage: 'About JoomPro',
    description: '[title] About Main',
  },
});

export const bestProductsMessages = defineMessages({
  title: {
    defaultMessage: 'Find everything you need in the best catalog of products from China',
    description: '[title] Best Products Main',
  },
});

export const heroSectionMessages = defineMessages({
  button: {
    defaultMessage: 'Request a quote',
    description: '[button] HeroSection Main',
  },
  description: {
    defaultMessage:
      'Explore our catalog and let JoomPro manage every stage of your import process—from sourcing suppliers to delivering to your warehouse',
    description: 'HeroSection Main description',
  },
  title: {
    defaultMessage: 'Comprehensive service to import from China safely and easily',
    description: '[title] HeroSection Main',
  },
});

export const importProcessMessages = defineMessages({
  button: {
    defaultMessage: 'Start now',
    description: '[button] Import process Main',
  },
  step1Description: {
    defaultMessage: 'Browse our catalog, find what you need, and check the estimated costs for your operation',
    description: '[description] Import process step Main',
  },
  step1Title: {
    defaultMessage: 'Choose your products',
    description: '[description] Import process step Main',
  },
  step2Description: {
    defaultMessage:
      'Add the products you want to import to your cart and send your final quote request. Our team will analyze your order and find the ideal supplier.',
    description: '[description] Import process step Main',
  },
  step2Title: {
    defaultMessage: 'Request your quote',
    description: '[description] Import process step Main',
  },
  step3Description: {
    defaultMessage:
      'Before placing a large order, you can request physical or virtual samples to ensure the products meet your expectations',
    description: '[description] Import process step Main',
  },
  step3Title: {
    defaultMessage: 'Request samples',
    description: '[description] Import process step Main',
  },
  step4Description: {
    defaultMessage:
      'Develop and personalize your products to position your brand as a relevant player in the market, distinguishing yourself from the competition',
    description: '[description] Import process step Main',
  },
  step4Title: {
    defaultMessage: 'Customize your product',
    description: '[description] Import process step Main',
  },
  step5Description: {
    defaultMessage:
      'Our team in China conducts quality control and inspects the batch before releasing it for shipment',
    description: '[description] Import process step Main',
  },
  step5Title: {
    defaultMessage: 'Quality assurance',
    description: '[description] Import process step Main',
  },
  step6Description: {
    defaultMessage:
      'We handle everything: transporting your goods from the factory to the port in China, and then to Brazil',
    description: '[description] Import process step Main',
  },
  step6Title: {
    defaultMessage: 'International freight',
    description: '[description] Import process step Main',
  },
  step7Description: {
    defaultMessage: 'We handle all the bureaucratic aspects, taking care of documentation and customs clearance',
    description: '[description] Import process step Main',
  },
  step7Title: {
    defaultMessage: 'Customs clearance',
    description: '[description] Import process step Main',
  },
  step8Description: {
    defaultMessage: 'We handle domestic shipping all the way to your warehouse in Brazil',
    description: '[description] Import process step Main',
  },
  step8Title: {
    defaultMessage: 'Receive at your warehouse',
    description: '[description] Import process step Main',
  },
  title: {
    defaultMessage: 'A simple step-by-step for your import process',
    description: '[title] Import process Main',
  },
});

export const newsMessages = defineMessages({
  socialMediaBlogButton: {
    defaultMessage: 'Visit our blog',
    description: '[button] Social Media Blog News Main',
  },
  socialMediaBlogTitle: {
    defaultMessage: 'Discover more exclusive content',
    description: '[title] Social Media Blog News Main',
  },
  socialMediaFollowTitle: {
    defaultMessage: 'Follow us on social media',
    description: '[title] Social Media News Main',
  },
  socialMediaYoutubeButton: {
    defaultMessage: 'Visit our channel',
    description: '[button] Social Media Youtube News Main',
  },
  socialMediaYoutubeTitle: {
    defaultMessage: 'Check out more videos on YouTube',
    description: '[title] Social Media Youtube News Main',
  },
  title: {defaultMessage: 'Stay informed about the world of importing', description: '[title] News Main'},
});

export const subscribeMessages = defineMessages({
  subtitle: {
    defaultMessage: 'Stay updated with the latest news and receive exclusive content on importing',
    description: '[subtitle] Subscribe Main',
  },
  title: {
    defaultMessage: 'Subscribe to our newsletter',
    description: '[title] Subscribe Main',
  },
});
