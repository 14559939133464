import {Image} from 'components/Image';
import {Card} from 'components/Main/Card';
import {useAnalytics} from 'lib/analytics';
import {reverseUrl} from 'lib/router';
import React, {useCallback} from 'react';
import {defineMessages, useIntl} from 'react-intl';
import {Button} from 'uikit/Button';
import styles from './index.module.scss';

const messages = defineMessages({
  button: {
    defaultMessage: 'Find products',
    description: '[button] Self service main page. Card request samples: action button.',
  },
  description: {
    defaultMessage:
      '<highlight>Request samples</highlight> and verify the quality of products before ordering full production',
    description: 'Self service main page. Card request samples: description.',
  },
});

type Props = {
  gridArea?: string;
};

export function CardRequestSamples({gridArea}: Props): React.ReactElement {
  const intl = useIntl();
  const analytics = useAnalytics();
  const onButtonClick = useCallback(
    () =>
      analytics.dataLayer({
        event: 'clickFindProducts',
      }),
    [analytics.dataLayer],
  );

  return (
    <Card color='black' gridArea={gridArea} padding='xs' rounded='m'>
      <div className={styles.container}>
        <div className={styles.content}>
          <Image loading='lazy' src='/illustrations/mainPtBr/request-samples.svg' />
          <div className={styles.description}>
            {intl.formatMessage(messages.description, {
              highlight: (value: React.ReactNode) => <span className={styles.highlight}>{value}</span>,
            })}
          </div>
          <div className={styles.actions}>
            <Button color='accent' href={reverseUrl.search()} onClick={onButtonClick} size='large' type='link'>
              {intl.formatMessage(messages.button)}
            </Button>
          </div>
        </div>
      </div>
    </Card>
  );
}
