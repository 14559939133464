import {QuatroLayout} from 'components/Main/Layout/Quatro';
import {NavigationId} from 'components/Main/lib/types';
import {Section} from 'components/Main/Section';
import {TestIdProp} from 'lib/testing/types';
import React from 'react';
import {CardAbout} from './CardAbout';
import {CardAgreement} from './CardAgreement';
import {CardGallery} from './CardGallery';
import {CardSuppliers} from './CardSuppliers';

export type SectionAboutTestId = unknown;

type Props = TestIdProp<SectionAboutTestId>;

export function SectionAbout({testId}: Props): React.ReactElement {
  return (
    <Section color='white' id={NavigationId.ABOUT} testId={testId}>
      <QuatroLayout
        blockA={<CardSuppliers />}
        blockB={<CardAgreement />}
        blockC={<CardGallery />}
        blockD={<CardAbout />}
      />
    </Section>
  );
}
