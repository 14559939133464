import {Image} from 'components/Image';
import {ButtonLink} from 'components/Main/Button';
import {Card, CardPadding} from 'components/Main/Card';
import {Section} from 'components/Main/Section';
import {Typography} from 'components/Main/Typography';
import {reverseUrl} from 'lib/router';
import {TestIdProp} from 'lib/testing/types';
import React from 'react';
import {CardContent} from './CardContent';
import styles from './index.module.scss';

export type SectionServicesTestId = unknown;

type Props = TestIdProp<SectionServicesTestId>;

const CARD_PADDING: CardPadding = ['s', 'm'];

export function SectionPayments({testId}: Props): React.ReactElement {
  /* eslint-disable max-len, no-irregular-whitespace */
  return (
    <Section
      color='bronze'
      testId={testId}
      title='Платите так, как удобно вам'
      titleCenteringOnMobile
      titleMessage='Лучшие финансовые условия – без переплат и скрытых комиссий'
    >
      <div className={styles.content}>
        <Card color='black' gridArea='blockA' padding={CARD_PADDING}>
          <CardContent
            image={<Image className={styles.image1} src='/illustrations/mainRu/sectionPayments/image1.svg' />}
            message='При заключении договора вы платите аванс в размере 30% от стоимости товара в Китае— оставшуюся сумму после проведения проверки качества'
            title='Отсрочка платежа'
          />
        </Card>
        <Card color='black' gridArea='blockB' padding={CARD_PADDING}>
          <CardContent
            image={<Image className={styles.image2} src='/illustrations/mainRu/sectionPayments/image2.svg' />}
            message='Наши партнеры предложат вам специальные условия получения финансирования для заказа через ДжумПро'
            title='Выгодное финансирование от партнеров'
          />
        </Card>
        <Card border='black' color='box' gridArea='blockC' padding={CARD_PADDING}>
          <div className={styles.consultationCard}>
            <div className={styles.text}>
              <Typography kind='promo' size='xl' variant='accent'>
                Выберите удобный вариант оплаты, и развивайте ваш бизнес прямо сейчас
              </Typography>
            </div>
            <ButtonLink href={reverseUrl.payments()} rounded target='_blank'>
              Подробнее про финансирование
            </ButtonLink>
          </div>
        </Card>
      </div>
    </Section>
  );
  /* eslint-enable max-len, no-irregular-whitespace */
}
