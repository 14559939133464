import {Image} from 'components/Image';
import {Card} from 'components/Main/Card';
import {useAnalytics} from 'lib/analytics';
import {useAppEnv} from 'lib/appEnv';
import React, {useCallback} from 'react';
import {defineMessages, useIntl} from 'react-intl';
import {Button} from 'uikit/Button';
import styles from './index.module.scss';

const messages = defineMessages({
  button: {
    defaultMessage: 'Learn more',
    description: '[button] Self service main page. Card financing trade: action button',
  },
  description: {
    defaultMessage:
      'Consult credit options to make your import viable, receiving the products at first and planning payment for the future.',
    description: 'Self service main page. Card financing trade: description.',
  },
  title: {
    defaultMessage: 'Explore trade financing',
    description: 'Self service main page. Card financing trade: title.',
  },
});

export type Props = {
  gridArea?: string;
  onClick(): void;
};

export function CardFinancingTrade({onClick, gridArea}: Props): React.ReactElement {
  const intl = useIntl();
  const appEnv = useAppEnv();
  const analytics = useAnalytics();
  const handleClick = useCallback(() => {
    onClick();
    analytics.sendEvent({
      payload: {
        pageUrl: window.location.href,
        position: 'tradeFinancingCard',
        source: appEnv.currentPageName,
      },
      type: 'learnMoreClick',
    });
    analytics.dataLayer({event: 'clickLearnMore'});
  }, [onClick, analytics.sendEvent, appEnv.currentPageName, analytics.dataLayer]);

  return (
    <Card color='box' gridArea={gridArea} padding='xs' rounded='m'>
      <div className={styles.container}>
        <Image loading='lazy' src='/illustrations/mainPtBr/financing-trading.svg' />
        <div className={styles.content}>
          <div className={styles.header}>
            <div className={styles.title}>{intl.formatMessage(messages.title)}</div>
            <div className={styles.description}>{intl.formatMessage(messages.description)}</div>
          </div>
          <div className={styles.actions}>
            <Button color='black' onClick={handleClick} size='large'>
              {intl.formatMessage(messages.button)}
            </Button>
          </div>
        </div>
      </div>
    </Card>
  );
}
