import {Button} from 'components/Main/Button';
import {Card} from 'components/Main/Card';
import {MainContext} from 'components/Main/lib';
import {Typography} from 'components/Main/Typography';
import {buildMessage} from 'lib/intl';
import React, {useCallback, useContext} from 'react';
import {useIntl} from 'react-intl';
import styles from './index.module.scss';

const messages = {
  button: 'Подобрать товар',
  title: 'Мы — эксперты в работе с китайскими поставщиками',
};

export function CardSuppliers(): React.ReactElement {
  const intl = useIntl();
  const {onOpenConsultationDialog} = useContext(MainContext);
  const handleOpenConsultationDialog = useCallback(() => {
    onOpenConsultationDialog?.();
  }, [onOpenConsultationDialog]);

  return (
    <Card color='black' padding={['l', 'xl']}>
      <div className={styles.content}>
        <Typography color='white' kind='promo' variant='h3'>
          {buildMessage(intl, messages.title)}
        </Typography>
        <div className={styles.action}>
          <Button color='red' onClick={handleOpenConsultationDialog} rounded>
            {buildMessage(intl, messages.button)}
          </Button>
        </div>
      </div>
    </Card>
  );
}
