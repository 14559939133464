import {Category} from 'lib/category/types';
import {useDevicevars} from 'lib/devicevars';
import {forwardRef, ForwardedRef, useMemo} from 'react';
import {CardContactUs} from '../../components/CardContactUs';
import {CardWithSearch, Payload as CardWithSearchPayload} from '../../components/CardWithSearch';
import {Categories} from '../../components/Categories';
import {SearchProps} from '../../types';
import styles from './index.module.scss';

type Props = SearchProps & {
  categories: Category[];
  onContactUs?(): void;
};

export const BlockCatalog = forwardRef(
  (
    {categories, onSuggest, onContactUs, searchSuggestions, searchSuggestionsPhrase}: Props,
    ref: ForwardedRef<HTMLDivElement>,
  ): React.ReactElement => {
    const {landingBrPayload} = useDevicevars();
    const cardWithSearchPayload = useMemo<CardWithSearchPayload>(() => {
      if (landingBrPayload) {
        return landingBrPayload;
      }

      return {
        countOfCategories: 0,
        countOfProducts: 0,
        countOfSuppliers: 0,
      };
    }, [landingBrPayload]);

    return (
      <div className={styles.container} ref={ref}>
        <div className={styles.header}>
          <CardWithSearch
            onSuggest={onSuggest}
            searchSuggestions={searchSuggestions}
            searchSuggestionsPhrase={searchSuggestionsPhrase}
            {...cardWithSearchPayload}
          />
          <CardContactUs onContact={onContactUs} />
        </div>
        <div className={styles.categories}>
          <Categories categories={categories} />
        </div>
      </div>
    );
  },
);
