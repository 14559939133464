import {CardMedia} from 'components/Main/CardMedia';
import React from 'react';
import styles from './index.module.scss';

export function CardAgreement(): React.ReactElement {
  const badge = (
    // eslint-disable-next-line no-irregular-whitespace
    <span className={styles.badge}>☺ подписание соглашения о сотрудничестве с Гуандонской ассоциацией</span>
  );

  return (
    <CardMedia
      badge={badge}
      badgeAlign='right-bottom'
      color='gray'
      illustration='/illustrations/main2/sectionAbout/figure_1.jpeg'
      illustrationAlign='top'
    />
  );
}
