import {CardMedia} from 'components/Main/CardMedia';
import React from 'react';

export function CardMeasure(): React.ReactElement {
  return (
    <CardMedia
      badge='☺ процесс измерения'
      badgeAlign='left-bottom'
      color='black'
      illustration='/illustrations/main2/sectionQuality/figure_2.jpeg'
    />
  );
}
