import cn from 'classnames';
import {buildMessage} from 'lib/intl';
import React from 'react';
import {useIntl} from 'react-intl';
import {UnorderedListState, OrderedListState} from '../types';
import styles from './index.module.scss';

type UnorderedListProps = {
  data: UnorderedListState;
};

type OrderedListProps = {
  data: OrderedListState;
};

type ListItemProps = {
  children: React.ReactNode;
};

export function ListItem({children}: ListItemProps): React.ReactElement {
  return <li>{children}</li>;
}

export function OrderedList({data: {options = {}, value}}: OrderedListProps): React.ReactElement {
  const intl = useIntl();
  const {start = 1, label, values} = options;

  return (
    <>
      {label && <p className={styles.label}>{buildMessage(intl, label)}</p>}
      <ol className={cn(styles.list, styles.ordered)} start={start}>
        {value.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <ListItem key={index}>{buildMessage(intl, item, values)}</ListItem>
        ))}
      </ol>
    </>
  );
}

export function UnorderedList({data: {options = {}, value}}: UnorderedListProps): React.ReactElement {
  const intl = useIntl();
  const {listStyleType = 'disc', label, values} = options;

  return (
    <>
      {label && <p className={styles.label}>{buildMessage(intl, label)}</p>}
      <ul
        className={cn(styles.list, styles.unordered, {
          [styles.disc]: listStyleType === 'disc',
          [styles.decimal]: listStyleType === 'decimal',
        })}
      >
        {value.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <ListItem key={index}>{buildMessage(intl, item, values)}</ListItem>
        ))}
      </ul>
    </>
  );
}
