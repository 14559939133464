import {usePopupState} from '@joomcode/deprecated-utils/react/usePopupState';
import {HeaderActions} from 'components/Header/HeaderActions';
import {HeaderBurgerMenu} from 'components/Header/HeaderBurgerMenu';
import {HeaderLayout} from 'components/Header/HeaderLayout';
import {HeaderLogo} from 'components/Header/HeaderLogo';
import {useNavLinks} from 'components/Header/hooks/useNavLinks';
import {HeaderAction} from 'components/Header/types';
import {Reducer} from 'components/Layout/Reducer';
import {MainContextProfileValue} from 'components/Main/lib';
import {HeaderCatalog} from 'containers/HeaderContainer/HeaderCatalog';
import {Category} from 'lib/category/types';
import {useCallbackWithoutArgs} from 'lib/hooks';
import React from 'react';
import styles from './index.module.scss';
import {NavItem} from './Nav/Item';

type Props = MainContextProfileValue & {
  actions: HeaderAction[];
  cartItemsCount?: number;
  categories: Category[];
};

export function Navigation({
  actions,
  categories,
  cartItemsCount,
  onSignIn,
  onSignOut,
  user,
}: Props): React.ReactElement {
  const navLinks = useNavLinks();
  const sidebarState = usePopupState();
  const handleSignIn = useCallbackWithoutArgs(onSignIn);
  const handleSignOut = useCallbackWithoutArgs(onSignOut);

  return (
    <Reducer>
      <HeaderLayout
        actions={
          <div className={styles.side}>
            <div className={styles.nav}>
              {navLinks.map((item) => (
                <NavItem href={item.href} key={item.href} label={item.message} />
              ))}
            </div>
            <HeaderActions
              actions={actions}
              cartItemsCount={cartItemsCount}
              direction='horizontal'
              onSignIn={handleSignIn}
              onSignOut={handleSignOut}
              theme='dark'
              user={user}
            />
          </div>
        }
        burger={<HeaderBurgerMenu expanded={sidebarState.isOpen} onClick={sidebarState.open} />}
        logo={<HeaderLogo />}
        theme='dark'
      />
      {sidebarState.isOpen && (
        <HeaderCatalog actions={actions} categories={categories} onClose={sidebarState.close} theme='dark' />
      )}
    </Reducer>
  );
}
