import {CardInfo} from 'components/Main/CardInfo';
import {buildMessage} from 'lib/intl';
import React from 'react';
import {useIntl} from 'react-intl';

/* eslint-disable max-len */
const messages = {
  subtitle:
    'Вы убедитесь в качестве партии еще до того, как получите ее: мы пришлем фотографии, сделаем видео-обзор или проведем видеозвонок, чтобы показать вам товар. Также, товар можно будет проверить лично — для этого мы доставим вам образцы.',
  title: '✓ Проверка качества перед покупкой',
};
/* eslint-enable max-len */

export function CardCheck(): React.ReactElement {
  const intl = useIntl();

  return (
    <CardInfo
      align='left'
      color='box'
      subtitle={buildMessage(intl, messages.subtitle)}
      title={buildMessage(intl, messages.title)}
    />
  );
}
