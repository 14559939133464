import React from 'react';
import {UnorderedList, OrderedList} from './List';
import {Paragraph} from './Paragraph';
import {DataItem, DataType} from './types';

type Props = {
  data: DataItem[];
};

export function ArticleFactory({data}: Props): React.ReactElement {
  return (
    <>
      {data.map((item, index) => {
        switch (item.type) {
          case DataType.PARAGRAPH:
            // eslint-disable-next-line react/no-array-index-key
            return <Paragraph data={item} key={index} />;
          case DataType.UNORDERED_LIST:
            // eslint-disable-next-line react/no-array-index-key
            return <UnorderedList data={item} key={index} />;
          case DataType.ORDERED_LIST:
            // eslint-disable-next-line react/no-array-index-key
            return <OrderedList data={item} key={index} />;
          default:
            return null;
        }
      })}
    </>
  );
}
