import {NavigationItem} from 'components/Header/HeaderNavigationShort/NavigationMenuItem';
import ChevronDownIcon from 'icons/chevron-down-16.svg';
import IconEye from 'icons/eye-filled.svg';
import {useAnalytics} from 'lib/analytics';
import {useAppEnv} from 'lib/appEnv';
import {useEvent} from 'lib/effector';
import {reverseUrl} from 'lib/router';
import {openContactUsDialog} from 'models/selfService/contactUs';
import {useCallback, useState} from 'react';
import {defineMessages, useIntl} from 'react-intl';
import {growBusinessMessages} from './mesages';

const messages = defineMessages({
  aboutUs: {
    defaultMessage: 'About us',
    description: 'Header. Navigation. Menu label',
  },
  howItWorks: {
    defaultMessage: 'How it works',
    description: 'Header. Navigation. Menu label',
  },
  resources: {
    defaultMessage: 'Resources',
    description: 'Header. Navigation. Menu label',
  },
  solutions: {
    defaultMessage: 'Solutions',
    description: 'Header. Navigation. Menu label',
  },
});

const solutionsMessages = defineMessages({
  feature: {
    defaultMessage: 'Feature',
    description: 'Header. Navigation. Solutions menu item',
  },
  marketplaceCatalog: {
    defaultMessage: 'Marketplace & Catalog',
    description: 'Header. Navigation. Solutions menu item',
  },
  pulse: {
    defaultMessage: 'Joom Pulse',
    description: 'Header. Navigation. Solutions menu item',
  },
});

const resourcesMessages = defineMessages({
  blog: {
    defaultMessage: 'Blog',
    description: 'Header. Navigation. resources menu item',
  },
  cases: {
    defaultMessage: 'Cases',
    description: 'Header. Navigation. resources menu item',
  },
  socialMedia: {
    defaultMessage: 'Social media',
    description: 'Header. Navigation. resources menu item',
  },
});

const aboutUsMessages = defineMessages({
  aboutJoom: {
    defaultMessage: 'About Joom',
    description: 'Header. Navigation. resources menu item',
  },
  contactUs: {
    defaultMessage: 'Contact us',
    description: 'Header. Navigation. resources menu item',
  },
});

export const useNavigationItems = (): {forceStep: number; navigationItems: NavigationItem[]} => {
  const intl = useIntl();
  const handleOpenContactUsDialog = useEvent(openContactUsDialog);
  const [forceStep, setForceStep] = useState(0);

  const appEnv = useAppEnv();
  const analytics = useAnalytics();

  const handleContactUs = useCallback(() => {
    handleOpenContactUsDialog?.();
    analytics.sendEvent({
      payload: {
        pageUrl: window.location.href,
        position: 'header',
        source: appEnv.currentPageName,
      },
      type: 'contactUsClick',
    });
  }, [handleOpenContactUsDialog, appEnv.currentPageName, analytics.sendEvent]);

  return {
    forceStep,
    navigationItems: [
      {
        icon: <IconEye />,
        label: intl.formatMessage(messages.howItWorks),
        link: '#import',
        name: 'howItWork',
      },
      {
        actions: [
          {
            label: intl.formatMessage(solutionsMessages.marketplaceCatalog),
            link: '#catalog',
            name: 'catalog',
          },
          ...new Array(6).fill(0).map((_, index) => ({
            label: intl.formatMessage(
              growBusinessMessages[`step${index + 1}Label` as keyof typeof growBusinessMessages],
            ),
            link: '#grow',
            name: `business${index + 1}`,
            onClick: () => {
              setForceStep(index);
            },
          })),
          {
            label: intl.formatMessage(solutionsMessages.pulse),
            link: 'https://joompulse.com/',
            name: 'joomPulse',
          },
        ],
        icon: <ChevronDownIcon />,
        label: intl.formatMessage(messages.solutions),
        name: 'solutions',
        rotateOnOpen: true,
      },
      {
        actions: [
          {
            label: intl.formatMessage(resourcesMessages.cases),
            link: '#cases',
            name: 'cases',
          },
          {
            label: intl.formatMessage(resourcesMessages.blog),
            link: reverseUrl.blog(),
            name: 'blog',
          },
          {
            label: intl.formatMessage(resourcesMessages.socialMedia),
            link: '#socialMedia',
            name: 'socialMedia',
          },
        ],
        icon: <ChevronDownIcon />,
        label: intl.formatMessage(messages.resources),
        name: 'resources',
        rotateOnOpen: true,
      },
      {
        actions: [
          {
            label: intl.formatMessage(aboutUsMessages.contactUs),
            name: 'contactUs',
            onClick: handleContactUs,
          },
          {
            label: intl.formatMessage(aboutUsMessages.aboutJoom),
            link: reverseUrl.about(),
            name: 'aboutJoom',
          },
        ],
        icon: <ChevronDownIcon />,
        label: intl.formatMessage(messages.aboutUs),
        name: 'aboutUs',
        rotateOnOpen: true,
      },
    ],
  };
};
