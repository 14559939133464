import {Image} from 'components/Image';
import {Card} from 'components/Main/Card';
import {useAnalytics} from 'lib/analytics';
import {useAppEnv} from 'lib/appEnv';
import React, {useCallback} from 'react';
import {defineMessages, useIntl} from 'react-intl';
import {Button} from 'uikit/Button';
import styles from './index.module.scss';

const messages = defineMessages({
  button: {
    defaultMessage: 'Learn more',
    description: '[button] Self service main page. Card pay later: action button.',
  },
  description: {
    defaultMessage: 'Buy now and <bold>pay later</bold> with trade financing options',
    description: 'Self service main page. Card pay later: description.',
  },
});

export type Props = {
  gridArea?: string;
  onClick(): void;
};

export function CardPayLater({onClick, gridArea}: Props): React.ReactElement {
  const intl = useIntl();
  const appEnv = useAppEnv();
  const analytics = useAnalytics();
  const handleClick = useCallback(() => {
    onClick();
    analytics.sendEvent({
      payload: {
        pageUrl: window.location.href,
        position: 'payLaterCard',
        source: appEnv.currentPageName,
      },
      type: 'learnMoreClick',
    });
    analytics.dataLayer({event: 'clickLearnMore'});
  }, [onClick, analytics.sendEvent, appEnv.currentPageName, analytics.dataLayer]);

  return (
    <Card color='box' gridArea={gridArea} padding='xs' rounded='m'>
      <div className={styles.container}>
        <div className={styles.content}>
          <Image loading='lazy' src='/illustrations/mainPtBr/pay-later.svg' />
          <div className={styles.description}>
            {intl.formatMessage(messages.description, {
              bold: (value: React.ReactNode) => <b>{value}</b>,
            })}
          </div>
          <div className={styles.actions}>
            <Button color='black' onClick={handleClick} size='large'>
              {intl.formatMessage(messages.button)}
            </Button>
          </div>
        </div>
      </div>
    </Card>
  );
}
