import {CardCompact} from 'components/Main/CardCompact';
import {CardMedia} from 'components/Main/CardMedia';
import {VihalsLayout} from 'components/Main/Layout/Vihals';
import {Typography} from 'components/Main/Typography';
import {buildMessage} from 'lib/intl';
import React from 'react';
import {useIntl} from 'react-intl';
import styles from './index.module.scss';

export const messages = {
  title: '® Помогаем построить свой бренд в России',
};

export function BlockCustomisation(): React.ReactElement {
  const intl = useIntl();

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Typography kind='promo' variant='h2'>
          {buildMessage(intl, messages.title)}
        </Typography>
      </div>
      <div className={styles.content}>
        <VihalsLayout
          blockA={
            <CardCompact
              border='black'
              color='white'
              illustration='/illustrations/main2/sectionServices/customization_1.svg'
              title='Производим товар по индивидуальным характеристикам'
            />
          }
          blockB={
            <CardCompact
              border='black'
              color='white'
              illustration='/illustrations/main2/sectionServices/customization_2.svg'
              title='Создаем индивидуальную упаковку'
            />
          }
          blockC={
            <CardCompact
              border='black'
              color='white'
              illustration='/illustrations/mainRu/sectionServices/customization_4.svg'
              title='Переводим инструкции с китайского на русский'
            />
          }
          blockD={
            <CardMedia
              color='black'
              illustration='/illustrations/main2/sectionServices/customization_1.png'
              illustrationPassiveSize
            />
          }
          blockE={
            <CardCompact
              border='black'
              color='white'
              illustration='/illustrations/main2/sectionServices/customization_3_ru.svg'
              title='Маркируем товар в системе «Честный знак» и наносим штрих-коды маркетплейсов на коробки'
            />
          }
        />
      </div>
    </div>
  );
}
