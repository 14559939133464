import {usePopupState} from '@joomcode/deprecated-utils/react/usePopupState';
import {Reducer} from 'components/Layout/Reducer';
import {Footer} from 'components/Main/Footer';
import {MainLayout} from 'components/Main/Layout';
import {MainContext} from 'components/Main/lib';
import {About} from 'components/MainForPtBr/components/About';
import {CardSubscribe} from 'components/MainForPtBr/components/CardSubscribe';
import {Cases} from 'components/MainForPtBr/components/Cases';
import {GrowBusiness} from 'components/MainForPtBr/components/GrowBussines';
import {ImportProcess} from 'components/MainForPtBr/components/ImportProcess';
import {News} from 'components/MainForPtBr/components/News';
import {Partners} from 'components/MainForPtBr/components/Partners';
import {BlockProducts} from 'components/MainForPtBr/components/Promotions';
import {CardContactUs} from 'components/MainForPtBr/components/UpdatedCardContactUs';
import {CardWithSearch} from 'components/MainForPtBr/components/UpdatedCardWithSearch';
import {useStore} from 'lib/effector';
import {useIntersectionObserver} from 'lib/hooks';
import {$categories} from 'models/categories';
import {$selfServicePromotions} from 'models/selfService/mainPage';
import React, {useContext, useRef, useState} from 'react';
import {useConfig} from './config';
import {Header} from './Header';
import styles from './index.module.scss';
import {ShortHeader} from './ShortHeader';
import {useNavigationItems} from './useNavigationItems';

export function MainPtBrV2() {
  const {promotions} = useStore($selfServicePromotions);
  const {categories} = useStore($categories);
  const {forceStep, navigationItems} = useNavigationItems();
  const config = useConfig(promotions);
  const blockCatalogRef = useRef<HTMLDivElement>(null);
  const [navVisible, setNavVisible] = useState(true);
  const {onOpenContactUsDialog} = useContext(MainContext);
  const catalogPopup = usePopupState();

  useIntersectionObserver({
    callback: ([entry]) => {
      setNavVisible(entry.isIntersecting);
    },
    options: {
      rootMargin: '-84px', // header height
    },
    targetRef: blockCatalogRef,
  });

  return (
    <MainLayout
      footer={<Footer />}
      navigation={
        navVisible ? (
          <div className={styles.shortHeader}>
            <ShortHeader
              categories={categories}
              isCatalogOpen={catalogPopup.isOpen}
              navigationItems={navigationItems}
              onBurgerClick={catalogPopup.toggle}
              onCatalogClose={catalogPopup.close}
              onCatalogOpen={catalogPopup.open}
            />
          </div>
        ) : (
          <Header
            categories={categories}
            isCatalogOpen={catalogPopup.isOpen}
            navigationItems={navigationItems}
            onBurgerClick={catalogPopup.toggle}
            onCatalogClose={catalogPopup.close}
            onCatalogOpen={catalogPopup.open}
          />
        )
      }
    >
      <div className={styles.heroSectionContainer}>
        <div className={styles.screen}>
          <Reducer>
            <div className={styles.headerContainer}>
              <CardWithSearch
                categories={categories}
                countOfCategories={config.bestProducts.countOfCategories}
                countOfProducts={config.bestProducts.countOfProducts}
                countOfSuppliers={config.bestProducts.countOfSuppliers}
                isCatalogOpen={catalogPopup.isOpen}
                onCatalogOpen={catalogPopup.open}
                openLinkInNewTab={false}
                ref={blockCatalogRef}
              />
              <CardContactUs onContact={onOpenContactUsDialog} />
            </div>
          </Reducer>
          <Reducer>
            {!!config.bestProducts.promotions.length && (
              <div className={styles.wrapper}>
                <div className={styles.point} id='catalog'></div>
                <BlockProducts
                  buttonLink={config.bestProducts.buttonLink}
                  promotions={config.bestProducts.promotions}
                  shownProductsCount={12}
                />
              </div>
            )}
          </Reducer>
        </div>
        <div className={styles.container}>
          <Reducer>
            <div className={styles.content}>
              <div className={styles.wrapper}>
                <div className={styles.point} id='grow' />
              </div>
              <GrowBusiness forceStep={forceStep} {...config.growBusiness} />
            </div>
          </Reducer>
          <div className={styles.wrapper}>
            <div className={styles.point} id='import' />
            <ImportProcess {...config.importProcess} />
          </div>
          <Reducer>
            <div className={styles.content}>
              <About {...config.about} />
            </div>
          </Reducer>
          <Reducer>
            <div className={styles.content}>
              <Partners />
            </div>
          </Reducer>
          <Reducer>
            <div className={styles.wrapper}>
              <div className={styles.point} id='cases' />
            </div>
            <div className={styles.content}>
              <Cases {...config.cases} />
            </div>
          </Reducer>
          <Reducer>
            <div className={styles.wrapper}>
              <div className={styles.point} id='socialMedia' />
            </div>
            <div className={styles.content}>
              <div className={styles.newsBlock}>
                <News {...config.news} />
                <CardSubscribe {...config.subscribe} />
              </div>
            </div>
          </Reducer>
        </div>
      </div>
    </MainLayout>
  );
}
