import {Button} from 'components/Main/Button';
import {MainContext} from 'components/Main/lib';
import {buildMessage} from 'lib/intl';
import React, {useContext, useCallback} from 'react';
import {useIntl} from 'react-intl';

const messages = {
  button: 'Подобрать товар',
};

export function Action(): React.ReactElement {
  const intl = useIntl();
  const {onOpenConsultationDialog} = useContext(MainContext);
  const handleOpenConsultationDialog = useCallback(() => {
    onOpenConsultationDialog?.();
  }, [onOpenConsultationDialog]);

  return (
    <Button color='black' onClick={handleOpenConsultationDialog} rounded>
      {buildMessage(intl, messages.button)}
    </Button>
  );
}
