import React from 'react';
import {useIntl} from 'react-intl';
import {CardPossibility} from '../CardPossibility';
import {data} from './data';
import styles from './index.module.scss';

type Props = {
  gridArea?: string;
};

export function Possibilities({gridArea}: Props): React.ReactElement {
  const intl = useIntl();

  return (
    <div className={styles.container} style={{gridArea}}>
      {data.map(({illustration, description, id}) => (
        <div className={styles.item} key={illustration}>
          <CardPossibility
            description={intl.formatMessage(description, {
              highlight: (value: React.ReactNode) => <span className={styles.highlight}>{value}</span>,
            })}
            id={id}
            illustration={illustration}
          />
        </div>
      ))}
    </div>
  );
}
