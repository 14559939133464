import {BlogArticlesPayload} from 'api/blogArticle/loadBlogArticles';
import {getPromotionIdList} from 'api/promotions';
import {Metadata} from 'components/Metadata';
import {MainPage as MainPageBase} from 'containers/MainPage';
import {useBaseUrl} from 'lib/baseUrl';
import {useEvent} from 'lib/effector';
import {Language} from 'lib/language/types';
import {OpenGraph} from 'lib/openGraph/types';
import {loadBlogArticlesFx} from 'models/blogArticles';
import {loadMainPromotionFx, resetMainPromotions} from 'models/selfService/mainPage';
import {$user} from 'models/user';
import {NextPageContext} from 'next';
import Router from 'next/router';
import React, {useEffect, useMemo} from 'react';
import {defineMessages} from 'react-intl';

const messages = defineMessages({
  description: {
    defaultMessage: 'A reliable and convenient service for wholesale purchases',
    description: 'Description of the main page.',
  },
  title: {
    defaultMessage: 'JoomPro | Wholesale products from China',
    description: 'HTML title of the main page.',
  },
});

export default function MainPage(): React.ReactElement {
  const baseUrl = useBaseUrl();
  const resetMainPromotionsEvent = useEvent(resetMainPromotions);
  const openGraphData = useMemo<OpenGraph>(
    () => ({
      image: {
        height: 800,
        url: `${baseUrl.origin}/illustrations/mainBanner.png`,
        width: 800,
      },
    }),
    [],
  );

  useEffect(() => resetMainPromotionsEvent, []);

  return (
    <>
      <Metadata description={messages.description} openGraph={openGraphData} title={messages.title}>
        <meta content='s4zt2oez07ggo2vdu6ns5x4l98o5sk' name='facebook-domain-verification' />
      </Metadata>
      <MainPageBase />
    </>
  );
}

MainPage.getInitialProps = async ({client, store, locale, res}: NextPageContext) => {
  if (locale !== Language.RU) {
    const {landingConfig, catalog112024} = client?.config?.devicevarsInfo?.devicevars || {};
    const allPayload: BlogArticlesPayload = {locale};
    if (catalog112024) {
      const user = store.getState($user);
      if (!user.anonymous && landingConfig?.main?.catalogRedirect) {
        if (res) {
          res.writeHead(307, {location: '/search'});
          res.end();
        } else {
          Router.replace('/search');
        }
      } else {
        await store.dispatch(loadBlogArticlesFx, allPayload);
      }
    }
  }

  let promotionsIds: string[] = [];
  if (
    client.config.devicevarsInfo?.devicevars.landingConfig?.main.show &&
    client.config.devicevarsInfo?.devicevars.landingBr112024
  ) {
    await store.dispatch(resetMainPromotions);
    try {
      promotionsIds = await getPromotionIdList(client);
    } catch (e) {
      console.error(e);
    }
  } else {
    if (client.config.devicevarsInfo?.devicevars.landingBrPayload) {
      const {landingBrPayload} = client.config.devicevarsInfo.devicevars;
      promotionsIds = landingBrPayload.promotions;
    }
  }

  if (!!promotionsIds?.length) {
    await store.dispatch(resetMainPromotions);
    await promotionsIds.reduce((chain, promotionId) => {
      return chain.then(() => store.dispatch(loadMainPromotionFx, promotionId));
    }, Promise.resolve());
  }
};
