import {defineMessage} from 'react-intl';
import {DataItem} from './types';

export const data: DataItem[] = [
  {
    description: defineMessage({
      defaultMessage:
        'Our team specialized in purchasing in China is responsible for finding the ideal manufacturer for your needs',
      description: 'Self service main page. Benefit card description',
    }),
    id: 'bestSuppliersCard',
    illustration: '/illustrations/mainPtBr/benefit1.svg',
    title: defineMessage({
      defaultMessage: 'Best suppliers',
      description: 'Self service main page. Benefit card title',
    }),
  },
  {
    description: defineMessage({
      defaultMessage: ' We have a comprehensive and reliable logistics network',
      description: 'Self service main page. Benefit card description',
    }),
    id: 'ReliableDeliveriesCard',
    illustration: '/illustrations/mainPtBr/benefit2.svg',
    title: defineMessage({
      defaultMessage: 'Reliable deliveries',
      description: 'Self service main page. Benefit card title',
    }),
  },
  {
    description: defineMessage({
      defaultMessage: 'We ensure consistent quality of products through photos, videos and samples',
      description: 'Self service main page. Benefit card description',
    }),
    id: 'guaranteeQualityCard',
    illustration: '/illustrations/mainPtBr/benefit3.svg',
    title: defineMessage({
      defaultMessage: 'Guarantee quality',
      description: 'Self service main page. Benefit card title',
    }),
  },
  {
    description: defineMessage({
      defaultMessage: 'We manage each shipment with our visibility platform',
      description: 'Self service main page. Benefit card description',
    }),
    id: 'controlAllShipmentsCard',
    illustration: '/illustrations/mainPtBr/benefit4.svg',
    title: defineMessage({
      defaultMessage: 'Control all shipments',
      description: 'Self service main page. Benefit card title',
    }),
  },
];
