import {Link} from 'components/Link';
import {reverseUrl} from 'lib/router';
import React from 'react';
import {defineMessages, useIntl} from 'react-intl';
import {ItemContent} from '../ItemContent';
import IconEye from './eyes.svg';
import styles from './index.module.scss';

const messages = defineMessages({
  show: {
    defaultMessage: 'Show all categories',
    description: '[button] Button for expanding all categories.',
  },
});

export function ItemShowAll(): React.ReactElement {
  const intl = useIntl();
  const name = intl.formatMessage(messages.show);

  return (
    <Link href={reverseUrl.search()}>
      <ItemContent image={<IconEye className={styles.icon} />} name={name} />
    </Link>
  );
}
