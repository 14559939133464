import {DataState} from '@joomcode/deprecated-utils/dataState';
import {Reducer} from 'components/Layout/Reducer';
import React from 'react';
import {CardSubscribe} from '../components/CardSubscribe';
import {SubscribeHandler} from '../types';
import styles from './index.module.scss';

export type Props = {
  onSubscribe: SubscribeHandler;
  subscribeState: DataState;
};

export function SectionSubscribe({onSubscribe, subscribeState}: Props): React.ReactElement {
  return (
    <div className={styles.container}>
      <Reducer>
        <CardSubscribe onSubscribe={onSubscribe} state={subscribeState} />
      </Reducer>
    </div>
  );
}
