import {Image} from 'components/Image';
import RightArrowIcon from 'icons/long-arrow-right.svg';
import React from 'react';
import {Button} from 'uikit/Button';
import styles from './index.module.scss';

export type Props = {
  buttonLink?: string;
  buttonText?: string;
  description: string;
  imgPath: string;
  title: string;
};

export const About = ({title, description, imgPath, buttonLink, buttonText}: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.imageBox}>
        <Image className={styles.image} src={imgPath} />
      </div>

      <div className={styles.content}>
        <div className={styles.text}>
          <span className={styles.title}>{title}</span>
          <span className={styles.description}>{description}</span>
        </div>
        {buttonLink && (
          <div>
            <Button
              color='black'
              href={buttonLink}
              iconLeft={<RightArrowIcon />}
              label={buttonText}
              size='large'
              type='link'
            />
          </div>
        )}
      </div>
    </div>
  );
};
