import {HaugaLayout} from 'components/Main/Layout/Hauga';
import {NavigationId} from 'components/Main/lib/types';
import {Section} from 'components/Main/Section';
import {Typography} from 'components/Main/Typography';
import {buildMessage} from 'lib/intl';
import {TestIdProp} from 'lib/testing/types';
import React from 'react';
import {useIntl} from 'react-intl';
import {Action} from './Action';
import {CardCheck} from './CardCheck';
import {CardControl} from './CardControl';
import {CardMeasure} from './CardMeasure';
import {CardQuality} from './CardQuality';
import {CardVerify} from './CardVerify';
import styles from './index.module.scss';

export type SectionQualityTestId = unknown;

type Props = TestIdProp<SectionQualityTestId>;

const messages = {
  title: 'Абсолютная прозрачность и надежность ☂',
};

export function SectionQuality({testId}: Props): React.ReactElement {
  const intl = useIntl();

  return (
    <Section color='black' id={NavigationId.QUALITY} testId={testId}>
      <div className={styles.container}>
        <Typography color='white' kind='promo' variant='h2'>
          {buildMessage(intl, messages.title)}
        </Typography>
        <HaugaLayout
          action={<Action />}
          blockA={<CardQuality />}
          blockB={<CardVerify />}
          blockC={<CardMeasure />}
          blockD={<CardCheck />}
          blockE={<CardControl />}
        />
      </div>
    </Section>
  );
}
