import {IntlShape, FormatNumberOptions} from 'react-intl';

export type FormatShorthandNumberOptions = Pick<FormatNumberOptions, 'compactDisplay'> & {
  startFormattingFrom?: number;
};

export function formatShorthandNumber(
  value: number,
  intl: IntlShape,
  options: FormatShorthandNumberOptions = {},
): string {
  const {startFormattingFrom = 10_000, compactDisplay = 'long'} = options;
  if (value >= startFormattingFrom) {
    return intl.formatNumber(value, {
      compactDisplay,
      notation: 'compact',
    });
  }

  return intl.formatNumber(value);
}
