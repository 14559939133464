import {usePopupState} from '@joomcode/deprecated-utils/react/usePopupState';
import cn from 'classnames';
import {Link} from 'components/Link';
import {useAnalytics} from 'lib/analytics';
import {useAppEnv} from 'lib/appEnv';
import React, {useCallback} from 'react';
import {ModalOverlay} from 'uikit/ModalOverlay';
import {DropdownItem} from './DropdownItem';
import styles from './index.module.scss';

type NavigationAction = {
  label: string;
  link?: string;
  name: string;
  onClick?: () => void;
};

export type NavigationItem = NavigationAction & {
  actions?: NavigationAction[];
  icon: React.ReactElement;
  rotateOnOpen?: boolean;
};

const NavigationLink = ({link, label, icon, onClick}: Omit<NavigationItem, 'link' | 'actions'> & {link: string}) => {
  const handleLinkClick = useCallback(() => {
    onClick?.();
  }, [onClick]);
  return (
    <Link className={styles.link} href={link} key={label} onClick={handleLinkClick}>
      {<div className={styles.linkIcon}>{icon}</div>}
      <div className={styles.linkText}>{label}</div>
    </Link>
  );
};

const NavigationButton = ({actions, label, icon, onClick, rotateOnOpen}: NavigationItem) => {
  const {open, close, isOpen} = usePopupState();

  const onButtonClick = useCallback(() => {
    onClick?.();
    open();
  }, []);

  return (
    <ModalOverlay disabled={!isOpen} inPlace onClose={close} transparent>
      <button className={styles.link} onClick={onButtonClick} type='button'>
        <div className={cn(styles.linkIcon, {[styles.rotate]: isOpen && rotateOnOpen})}>{icon}</div>
        <div className={styles.linkText}>{label}</div>
      </button>
      {isOpen && (
        <div className={styles.dropdown}>
          {actions?.map((action) => (
            <DropdownItem
              key={action.name}
              {...action}
              onClick={() => {
                action.onClick?.();
                close();
              }}
            />
          ))}
        </div>
      )}
    </ModalOverlay>
  );
};

export const NavigationMenuItem = ({actions, name, link, label, icon, onClick, rotateOnOpen}: NavigationItem) => {
  const analytics = useAnalytics();
  const appEnv = useAppEnv();
  const handleLinkClick = useCallback(() => {
    link &&
      analytics.sendEvent({
        payload: {
          pageUrl: window.location.href,
          source: appEnv.currentPageName,
          targetUrl: link,
        },
        type: 'navigationClick',
      });

    onClick?.();
  }, [analytics.sendEvent, appEnv.currentPageName, link, onClick]);

  const handleButtonClick = useCallback(() => {
    analytics.sendEvent({
      payload: {
        pageUrl: window.location.href,
        position: name,
        source: appEnv.currentPageName,
      },
      type: 'click',
    });
    onClick?.();
  }, [name, analytics.sendEvent, appEnv.currentPageName]);

  if (link) {
    return <NavigationLink icon={icon} label={label} link={link} name={name} onClick={handleLinkClick} />;
  }

  return (
    <NavigationButton
      actions={actions}
      icon={icon}
      label={label}
      link={link}
      name={name}
      onClick={handleButtonClick}
      rotateOnOpen={rotateOnOpen}
    />
  );
};
