import {Typography} from 'components/Main/Typography';
import React from 'react';
import styles from './index.module.scss';

type Props = {
  image: React.ReactNode;
  message: React.ReactNode;
  title: React.ReactNode;
};

export function CardContent({message, image, title}: Props): React.ReactElement {
  return (
    <div className={styles.content}>
      <div className={styles.imageWrap}>{image}</div>
      <div className={styles.texts}>
        <Typography kind='promo' variant='h3'>
          {title}
        </Typography>
        <Typography kind='promo' size='l' variant='text'>
          {message}
        </Typography>
      </div>
    </div>
  );
}
