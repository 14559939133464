import {Image} from 'components/Image';
import {Card} from 'components/Main/Card';
import {useAnalytics} from 'lib/analytics';
import {useAppEnv} from 'lib/appEnv';
import React, {useCallback} from 'react';
import styles from './index.module.scss';

export type Props = {
  description: React.ReactNode;
  id: string;
  illustration?: string;
};

export function CardPossibility({illustration, description, id}: Props): React.ReactElement {
  const analytics = useAnalytics();
  const appEnv = useAppEnv();
  const handleClick = useCallback(() => {
    analytics.sendEvent({
      payload: {
        pageUrl: window.location.href,
        position: id,
        source: appEnv.currentPageName,
      },
      type: 'click',
    });
  }, [id, analytics.sendEvent, appEnv.currentPageName]);

  return (
    <Card color='black' onClick={handleClick} padding='xs' rounded='m'>
      <div className={styles.container}>
        <div className={styles.content}>
          <Image loading='lazy' src={illustration} />
          <div className={styles.description}>{description}</div>
        </div>
      </div>
    </Card>
  );
}
