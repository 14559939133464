import {usePopupState} from '@joomcode/deprecated-utils/react/usePopupState';
import cn from 'classnames';
import {HeaderAction} from 'components/Header/types';
import {Reducer} from 'components/Layout/Reducer';
import {Footer} from 'components/Main/Footer';
import {MainLayout} from 'components/Main/Layout';
import {MainContext} from 'components/Main/lib';
import {FinancingDialog} from 'components/MainForPtBr/components/FinancingDialog';
import {SectionBenefits} from 'components/MainForPtBr/SectionBenefits';
import {SectionSubscribe} from 'components/MainForPtBr/SectionSubscribe';
import {SectionWelcome} from 'components/MainForPtBr/SectionWelcome';
import {HeaderContainer} from 'containers/HeaderContainer';
import {Category} from 'lib/category/types';
import {useEffectTask, useStore} from 'lib/effector';
import {useEvent} from 'lib/effector';
import {useIntersectionObserver} from 'lib/hooks';
import {subscribeFx} from 'models/blog';
import {$cartItemsCount} from 'models/cart/itemsCount';
import {$categories} from 'models/categories';
import {$searchSuggestions, searchPhraseSuggestFx} from 'models/search/suggest';
import {$selfServicePromotions} from 'models/selfService/mainPage';
import React, {useRef, useState, useContext} from 'react';
import styles from './index.module.scss';
import {Navigation} from './Navigation';

const HEADER_AVAILABLE_ACTIONS: HeaderAction[] = ['cart', 'profile'];
const DEFAULT_CATEGORIES_LIST: Category[] = [];

export function MainPtBrV1(): React.ReactElement {
  const blockCatalogRef = useRef<HTMLDivElement>(null);
  const [navVisible, setNavVisible] = useState(false);
  const {onOpenContactUsDialog, profile} = useContext(MainContext);
  const financingDialog = usePopupState();

  const {promotions} = useStore($selfServicePromotions);
  const cartItemsCountState = useStore($cartItemsCount);
  const {categories = DEFAULT_CATEGORIES_LIST} = useStore($categories);
  const {searchSuggestions, searchSuggestionsPhrase} = useStore($searchSuggestions);

  const subscribeTask = useEffectTask(subscribeFx, []);
  const searchPhraseSuggestEvent = useEvent(searchPhraseSuggestFx);

  useIntersectionObserver({
    callback: ([entry]) => {
      setNavVisible(!entry.isIntersecting);
    },
    targetRef: blockCatalogRef,
  });

  return (
    <>
      <MainLayout
        footer={<Footer />}
        navigation={
          profile && (
            <Navigation
              actions={HEADER_AVAILABLE_ACTIONS}
              cartItemsCount={cartItemsCountState.data}
              categories={categories}
              onSignIn={profile.onSignIn}
              onSignOut={profile.onSignOut}
              user={profile.user}
            />
          )
        }
        navigationPosition='static'
      >
        <div className={styles.container}>
          <SectionWelcome
            blockCatalogRef={blockCatalogRef}
            categories={categories}
            onContactUs={onOpenContactUsDialog}
            onFinancing={financingDialog.open}
            onSuggest={searchPhraseSuggestEvent}
            promotions={promotions}
            searchSuggestions={searchSuggestions}
            searchSuggestionsPhrase={searchSuggestionsPhrase}
          />
          <SectionBenefits onContactUs={onOpenContactUsDialog} onFinancing={financingDialog.open} />
          <SectionSubscribe onSubscribe={subscribeTask.perform} subscribeState={subscribeTask.state} />
        </div>
      </MainLayout>
      {financingDialog.isOpen && <FinancingDialog onClose={financingDialog.close} />}
      <div className={cn(styles.header, navVisible && styles.visible)}>
        <Reducer>
          <HeaderContainer actions={HEADER_AVAILABLE_ACTIONS} categories={categories} theme='dark' />
        </Reducer>
      </div>
    </>
  );
}
