import {defineMessage} from 'react-intl';
import {DataItem} from './types';

export const data: DataItem[] = [
  {
    description: defineMessage({
      defaultMessage: 'We negotiate directly with manufacturers, ensuring <highlight>competitive pricing</highlight>',
      description: 'Self service main page. Possibility card description',
    }),
    id: 'competitivePricingCard',
    illustration: '/illustrations/mainPtBr/possibility1.svg',
  },
  {
    description: defineMessage({
      defaultMessage:
        'We oversee every detail, from logistics to customs, ensuring a <highlight>reliable delivery</highlight>',
      description: 'Self service main page. Possibility card description',
    }),
    id: 'reliableDeliveryCard',
    illustration: '/illustrations/mainPtBr/possibility2.svg',
  },
  {
    description: defineMessage({
      defaultMessage: 'Our rigorous <highlight>quality control</highlight> ensures you receive exactly what you expect',
      description: 'Self service main page. Possibility card description',
    }),
    id: 'qualityControlCard',
    illustration: '/illustrations/mainPtBr/possibility3.svg',
  },
  {
    description: defineMessage({
      defaultMessage:
        'Our <highlight>team located in China</highlight> guarantees efficiency and safety on your importation',
      description: 'Self service main page. Possibility card description',
    }),
    id: 'teamLocatedInChinaCard',
    illustration: '/illustrations/mainPtBr/possibility4.svg',
  },
  {
    description: defineMessage({
      defaultMessage:
        'With our platform, you can <highlight>easily and transparently</highlight> track the status of your orders',
      description: 'Self service main page. Possibility card description',
    }),
    id: 'easilyAndTransparentlyCard',
    illustration: '/illustrations/mainPtBr/possibility5.svg',
  },
  {
    description: defineMessage({
      defaultMessage:
        'Our <highlight>dedicated account managers</highlight> are here to assist you every step of the way',
      description: 'Self service main page. Possibility card description',
    }),
    id: 'dedicatedAccountManagersCard',
    illustration: '/illustrations/mainPtBr/possibility6.svg',
  },
  {
    description: defineMessage({
      defaultMessage:
        '<highlight>Based in São Paulo</highlight>, we offer Portuguese support, local payment options, and nationwide delivery',
      description: 'Self service main page. Possibility card description',
    }),
    id: 'BasedInSãoPauloCard',
    illustration: '/illustrations/mainPtBr/possibility7.svg',
  },
  {
    description: defineMessage({
      defaultMessage: 'We provide quality <highlight>certificates</highlight> from Chinese supplier such as ISO 9001',
      description: 'Self service main page. Possibility card description',
    }),
    id: 'certificationsCard',
    illustration: '/illustrations/mainPtBr/possibility8.svg',
  },
  {
    description: defineMessage({
      defaultMessage:
        '<highlight>Customize products</highlight> to your needs with private labels, barcodes, translated manuals, and more',
      description: 'Self service main page. Possibility card description',
    }),
    id: 'customizeProductsCard',
    illustration: '/illustrations/mainPtBr/possibility9.svg',
  },
];
