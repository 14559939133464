import {SectionReviewsDataItem} from 'components/Main/SectionReviews/types';
import {defineMessage} from 'react-intl';

/* eslint-disable max-len */
export const data: SectionReviewsDataItem[] = [
  {
    id: 'main-section-review-review-3',
    label: defineMessage({
      defaultMessage: 'Vlad',
      description: 'Home page. Reviews section. Client name',
    }),
    review: {
      imageSrc: '/illustrations/main2/sectionReviews/vladimir.jpeg',
      info: defineMessage({
        defaultMessage: 'ATK',
        description: 'Home page. Reviews section. Client company',
      }),
      linkHref: 'https://vc.ru/trade/492824-v-usloviyah-nestabilnosti-ya-chuvstvuyu-sebya-uverenno',
      linkText: defineMessage({
        defaultMessage: 'Read more on VC',
        description: 'Home page. Reviews section. Link to client resource',
      }),
      messages: [
        defineMessage({
          defaultMessage:
            "We buy a wide range of products in China: from medical devices and massagers to children's punching bags. Before I started working with JoomPro, I arranged deliveries through a cargo agent. This experience was negative: the delivery deadlines for products worth more than 1 million rubles were disrupted, while I was actively pressured with payments. JoomPro experts have found factories that produce good quality products of interest to us. Thanks to our work with JoomPro, our annual turnover has more than doubled.",
          description: 'Home page. Reviews section.',
        }),
      ],
      name: defineMessage({
        defaultMessage: 'Vlad',
        description: 'Home page. Reviews section. Client name',
      }),
    },
  },
  {
    id: 'main-section-review-review-2',
    label: defineMessage({
      defaultMessage: 'Angelina',
      description: 'Home page. Reviews section. Client name',
    }),
    review: {
      imageSrc: '/illustrations/main2/sectionReviews/angelina.png',
      info: defineMessage({
        defaultMessage: 'Nuggle',
        description: 'Home page. Reviews section. Client company',
      }),
      messages: [
        defineMessage({
          defaultMessage:
            "Nuggle is a hookah eco-system: we are developing a line of tobacco and tobacco-free mixtures, as well as a smart product accounting system. Another one of our projects is Nuggle Shield, a technological solution for heating tobacco without coal. The list of its components consists of 15-20 parts with different requirements, parameters and characteristics, including circuit boards with chips based on a design developed by us. We realised that it's almost impossible to find enough components in this country. JoomPro helped us find them in China, ship them over and certify them for assembly here.",
          description: 'Home page. Reviews section.',
        }),
      ],
      name: defineMessage({
        defaultMessage: 'Angelina',
        description: 'Home page. Reviews section. Client name',
      }),
    },
  },
  {
    id: 'main-section-review-review-1',
    label: defineMessage({
      defaultMessage: 'Constantine',
      description: 'Home page. Reviews section. Client name',
    }),
    review: {
      messages: [
        defineMessage({
          defaultMessage:
            'I sell irrigators that I import from China. Before getting acquainted with JoomPro, I bought them through cargo delivery for 1500 rubles each. JoomPro experts helped me find the same product much cheaper  - for 1200 rubles each. I sell them on Wildberries for 2850 rubles - thanks to JoomPro I was able to put the price much lower than the market average, without losing the product margin.',
          description: 'Home page. Reviews section.',
        }),
      ],
      name: defineMessage({
        defaultMessage: 'Constantine',
        description: 'Home page. Reviews section. Client name',
      }),
    },
  },
  {
    id: 'main-section-review-review-4',
    label: defineMessage({
      defaultMessage: 'Alexander',
      description: 'Home page. Reviews section. Client name',
    }),
    review: {
      messages: [
        defineMessage({
          defaultMessage:
            'Before working with JoomPro, I had already ordered products from a Chinese supplier: the defect rate was quite high, but it suited me in comparison with previous experience. During the first quality check, JoomPro discovered a defect. The factory replaced the products and since then the percentage of illiquidity when working with this supplier has been minimised.',
          description: 'Home page. Reviews section.',
        }),
      ],
      name: defineMessage({
        defaultMessage: 'Alexander',
        description: 'Home page. Reviews section. Client name',
      }),
    },
  },
];
