import {Link} from 'components/Link';
import {useAnalytics} from 'lib/analytics';
import {useAppEnv} from 'lib/appEnv';
import {useCallback} from 'react';
import styles from './index.module.scss';

export type NavigationAction = {
  label: string;
  link?: string;
  name: string;
  onClick?: () => void;
};

export const DropdownItem = ({name, label, link, onClick}: NavigationAction): React.ReactElement => {
  const analytics = useAnalytics();
  const appEnv = useAppEnv();
  const handleLinkClick = useCallback(() => {
    link &&
      analytics.sendEvent({
        payload: {
          pageUrl: window.location.href,
          source: appEnv.currentPageName,
          targetUrl: link,
        },
        type: 'navigationClick',
      });

    onClick?.();
  }, [analytics.sendEvent, appEnv.currentPageName, link, onClick]);

  const handleButtonClick = useCallback(() => {
    analytics.sendEvent({
      payload: {
        pageUrl: window.location.href,
        position: name,
        source: appEnv.currentPageName,
      },
      type: 'click',
    });
    onClick?.();
  }, [name, analytics.sendEvent, appEnv.currentPageName]);

  if (!link) {
    return (
      <button className={styles.item} onClick={handleButtonClick} type='button'>
        <span className={styles.text}> {label} </span>
      </button>
    );
  }

  return (
    <Link className={styles.item} href={link} onClick={handleLinkClick}>
      <span className={styles.text}> {label} </span>
    </Link>
  );
};
