import cn from 'classnames';
import {DEFAULT_ACTIONS} from 'components/Header/constants';
import {HeaderBurgerMenu} from 'components/Header/HeaderBurgerMenu';
import {HeaderCatalogButton} from 'components/Header/HeaderCatalogButton';
import {HeaderLayout} from 'components/Header/HeaderLayout';
import {HeaderLogo} from 'components/Header/HeaderLogo';
import {HeaderNavigationShort} from 'components/Header/HeaderNavigationShort';
import {NavigationItem} from 'components/Header/HeaderNavigationShort/NavigationMenuItem';
import {HeaderAction, HeaderTheme} from 'components/Header/types';
import {Reducer} from 'components/Layout/Reducer';
import {useDividerNeeded} from 'components/Main/lib/hooks/useDividerNeeded';
import {HeaderActions} from 'containers/HeaderContainer/HeaderActions';
import {HeaderCatalog} from 'containers/HeaderContainer/HeaderCatalog';
import {HeaderSearchBar} from 'containers/HeaderContainer/HeaderSearchBar';
import {Category} from 'lib/category/types';
import React from 'react';
import styles from './index.module.scss';

type Props = {
  actions?: HeaderAction[];
  categories?: Category[];
  isCatalogOpen?: boolean;
  navigationItems: NavigationItem[];
  onBurgerClick?: () => void;
  onCatalogClose?: () => void;
  onCatalogOpen?: () => void;
  theme?: HeaderTheme;
};

export function Header({
  theme = 'light',
  actions = DEFAULT_ACTIONS,
  onCatalogOpen,
  onCatalogClose,
  onBurgerClick,
  isCatalogOpen = false,
  categories = [],
  navigationItems,
}: Props): React.ReactElement {
  const withDivider = useDividerNeeded();

  return (
    <div className={cn(styles.header, {[styles.divider]: withDivider})}>
      <Reducer>
        <HeaderLayout
          actions={<HeaderActions actions={actions} shape='classic' theme={theme} />}
          burger={<HeaderBurgerMenu expanded={isCatalogOpen} onClick={onBurgerClick} />}
          catalog={<HeaderCatalogButton expanded={isCatalogOpen} onClick={onCatalogOpen} shape='classic' />}
          footer={<HeaderNavigationShort items={navigationItems} />}
          logo={<HeaderLogo />}
          search={<HeaderSearchBar shape='classic' theme={theme} />}
          theme={theme}
        />
      </Reducer>
      {isCatalogOpen && onCatalogClose && (
        <HeaderCatalog actions={actions} categories={categories} onClose={onCatalogClose} theme={theme} />
      )}
    </div>
  );
}
