import React from 'react';
import styles from './index.module.scss';
import {NavigationItem, NavigationMenuItem} from './NavigationMenuItem';

type Props = {
  items: NavigationItem[];
};

export function HeaderNavigationShort({items}: Props) {
  return (
    <div className={styles.menu}>
      {items.map((item, index) => (
        <NavigationMenuItem key={index} {...item} />
      ))}
    </div>
  );
}
