import cnBind from 'classnames/bind';
import {buildMessage} from 'lib/intl';
import {MessageValues} from 'lib/intl/types';
import React from 'react';
import {useIntl} from 'react-intl';
import {ParagraphState, ParagraphColor} from '../types';
import styles from './index.module.scss';

const cn = cnBind.bind(styles);

type Props = {
  data: ParagraphState;
};

function getColorProcessor(color: ParagraphColor) {
  return (value: React.ReactNode) => <span className={cn(`color-${color}`)}>{value}</span>;
}

const defaultValues: MessageValues = {
  colorAccent: getColorProcessor('accent'),
  colorSecondary: getColorProcessor('secondary'),
};

export function Paragraph({data: {value, options = {}}}: Props): React.ReactElement {
  const intl = useIntl();
  const {bold, values, color} = options;
  const assignedValues = Object.assign({}, defaultValues, values);

  return (
    <p className={cn(styles.paragraph, {[styles.bold]: bold}, `color-${color}`)}>
      {buildMessage(intl, value, assignedValues)}
    </p>
  );
}
