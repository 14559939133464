import {Image} from 'components/Image';
import {Card} from 'components/Main/Card';
import React from 'react';
import {defineMessages, useIntl} from 'react-intl';
import styles from './index.module.scss';

const messages = defineMessages({
  text: {
    defaultMessage: 'For the industry',
    description: 'Self service main page. Card industry text',
  },
});

type Props = {
  gridArea?: string;
};

export function CardIndustry({gridArea}: Props): React.ReactElement {
  const intl = useIntl();

  return (
    <Card color='accent' gridArea={gridArea} padding='xs' rounded='m'>
      <div className={styles.container}>
        <div className={styles.content}>
          {/* TODO: wait for illustration until thursday */}
          <Image className={styles.image} loading='lazy' src='/illustrations/mainPtBr/industry.png' />
          <div className={styles.text}>{intl.formatMessage(messages.text)}</div>
        </div>
      </div>
    </Card>
  );
}
