export const data = [
  {
    button: 'Оптимизируйте процесс закупки',
    id: 'main-ru-section-welcome-offer-1',
    illustration: '/illustrations/mainRu/sectionWelcome/offer_1.png',
    title: 'Вы опытный импортер со своими поставщиками?',
  },
  {
    button: 'Начните закупать в Китае',
    id: 'main-ru-section-welcome-offer-2',
    illustration: '/illustrations/mainRu/sectionWelcome/offer_2.png',
    title: 'Только планируете импортировать из Китая?',
  },
];
