import {BlogArticlesPayload} from 'api/blogArticle/loadBlogArticles';
import {Image} from 'components/Image';
import {Props as AboutProps} from 'components/MainForPtBr/components/About';
import {Props as SubscribeProps} from 'components/MainForPtBr/components/CardSubscribe';
import {Props as CasesProps} from 'components/MainForPtBr/components/Cases';
import {Props as GrowBusinesProps} from 'components/MainForPtBr/components/GrowBussines';
import {Props as HeroSectionProps} from 'components/MainForPtBr/components/HeroSection';
import {Props as ImportProccessProps} from 'components/MainForPtBr/components/ImportProcess';
import {Props as NewsProps} from 'components/MainForPtBr/components/News';
import {Props as PromoNewsCardProps} from 'components/PromoNewsCard';
import EyeIcon from 'icons/eye-filled.svg';
import InstagramIcon from 'icons/instagram.svg';
import RecyclingIcon from 'icons/recycling.svg';
import FacebookIcon from 'icons/social-fb.svg';
import LinkedInIcon from 'icons/social-linked-in.svg';
import StarIcon from 'icons/star-fill.svg';
import {useAnalytics} from 'lib/analytics';
import {useAppEnv} from 'lib/appEnv';
import {useDevicevars} from 'lib/devicevars';
import {useEffectTask, useEvent, useStore} from 'lib/effector';
import {getSrcByWidth} from 'lib/image';
import {Promotion} from 'lib/promotion/types';
import {reverseUrl} from 'lib/router';
import {subscribeFx} from 'models/blog';
import {$blogArticles} from 'models/blogArticles';
import {toKey} from 'models/blogArticles/utils';
import {openContactUsDialog} from 'models/selfService/contactUs';
import Link from 'next/link';
import {useRouter} from 'next/router';
import {useCallback, useMemo} from 'react';
import {useIntl} from 'react-intl';
import {Button} from 'uikit/Button';
import styles from './index.module.scss';
import {
  aboutMessages,
  bestProductsMessages,
  casesMessages,
  growBusinessMessages,
  heroSectionMessages,
  importProcessMessages,
  newsMessages,
  subscribeMessages,
} from './mesages';

type CardWithSearchPayload = {
  countOfCategories: number;
  countOfProducts: number;
  countOfSuppliers: number;
};

type BestProductsProps = CardWithSearchPayload & {
  buttonLink: string;
  promotions: Promotion[];
  title: string;
};

type ConfigType = {
  about: AboutProps;
  bestProducts: BestProductsProps;
  cases: CasesProps;
  growBusiness: GrowBusinesProps;
  heroSection: HeroSectionProps;
  importProcess: ImportProccessProps;
  news: NewsProps;
  subscribe: SubscribeProps;
};

export const useConfig = (promotions: Promotion[]): ConfigType => {
  const {locale} = useRouter();
  const subscribeTask = useEffectTask(subscribeFx, []);
  const allPayload: BlogArticlesPayload = {locale};
  const blogArticlesState = useStore($blogArticles);
  const articles = blogArticlesState.byKey[toKey(allPayload)]?.data || [];
  const {landingConfig, landingBrPayload} = useDevicevars();
  const devicevarConfig = landingConfig?.main;
  const intl = useIntl();
  const onOpenContactUsDialog = useEvent(openContactUsDialog);

  const appEnv = useAppEnv();
  const analytics = useAnalytics();

  const handleContactUs = useCallback(() => {
    onOpenContactUsDialog?.();
    analytics.sendEvent({
      payload: {
        pageUrl: window.location.href,
        position: 'growBusiness',
        source: appEnv.currentPageName,
      },
      type: 'contactUsClick',
    });
  }, [onOpenContactUsDialog, appEnv.currentPageName, analytics.sendEvent]);

  const handleLinkClick = useCallback(
    (link: string) => {
      analytics.sendEvent({
        payload: {
          pageUrl: window.location.href,
          source: appEnv.currentPageName,
          targetUrl: link,
        },
        type: 'navigationClick',
      });
    },
    [analytics.sendEvent, appEnv.currentPageName],
  );

  const cardWithSearchPayload = useMemo<CardWithSearchPayload>(() => {
    if (landingBrPayload) {
      return landingBrPayload;
    }

    return {
      countOfCategories: 0,
      countOfProducts: 0,
      countOfSuppliers: 0,
    };
  }, [landingBrPayload]);

  const ctaButton = {
    buttonText: intl.formatMessage(growBusinessMessages.button),
    onButtonClick: handleContactUs,
  };

  return {
    about: {
      buttonLink: reverseUrl.about(),
      buttonText: intl.formatMessage(aboutMessages.button),
      description: intl.formatMessage(aboutMessages.description),
      imgPath: 'illustrations/mainPtBr/sectionAbout/about4.jpg',
      title: intl.formatMessage(aboutMessages.title),
    },
    bestProducts: {
      ...cardWithSearchPayload,
      buttonLink: `${reverseUrl.search()}?withPreliminaryAuth`,
      promotions,
      title: intl.formatMessage(bestProductsMessages.title),
    },
    cases: {
      cases: devicevarConfig?.cases || [],
      title: intl.formatMessage(casesMessages.title),
    },
    growBusiness: {
      steps: [
        {
          description: intl.formatMessage(growBusinessMessages.step1Description),
          illustration: 'illustrations/mainPtBr/sectionGrowBusiness/growBusiness1.1.png',
          label: intl.formatMessage(growBusinessMessages.step1Label),
          ...ctaButton,
        },
        {
          description: intl.formatMessage(growBusinessMessages.step2Description),
          illustration: 'illustrations/mainPtBr/sectionGrowBusiness/growBusiness2.1.png',
          label: intl.formatMessage(growBusinessMessages.step2Label),
          ...ctaButton,
        },
        {
          description: intl.formatMessage(growBusinessMessages.step3Description),
          illustration: (
            <div className={styles.imageWrapper}>
              <Image
                className={styles.image}
                src={'illustrations/mainPtBr/sectionGrowBusiness/growBusiness3.1.1.png'}
              />
              <Image
                className={styles.image}
                src={'illustrations/mainPtBr/sectionGrowBusiness/growBusiness3.1.2.png'}
              />
            </div>
          ),
          label: intl.formatMessage(growBusinessMessages.step3Label),
          ...ctaButton,
        },
        {
          description: intl.formatMessage(growBusinessMessages.step4Description),
          illustration: (
            <Image className={styles.svgImage} src={'illustrations/mainPtBr/sectionGrowBusiness/growBusiness4.1.png'} />
          ),
          label: intl.formatMessage(growBusinessMessages.step4Label),
          ...ctaButton,
        },
        {
          description: intl.formatMessage(growBusinessMessages.step5Description),
          illustration: 'illustrations/mainPtBr/sectionGrowBusiness/growBusiness5.1.jpg',
          label: intl.formatMessage(growBusinessMessages.step5Label),
          ...ctaButton,
        },
        {
          description: intl.formatMessage(growBusinessMessages.step6Description),
          illustration: (
            <Image className={styles.svgImage} src={'illustrations/mainPtBr/sectionGrowBusiness/growBusiness6.1.png'} />
          ),
          label: intl.formatMessage(growBusinessMessages.step6Label),
          ...ctaButton,
        },
      ],
      title: intl.formatMessage(growBusinessMessages.title),
    },
    heroSection: {
      buttonLink: `${reverseUrl.search()}?withPreliminaryAuth`,
      buttonText: intl.formatMessage(heroSectionMessages.button),
      description: intl.formatMessage(heroSectionMessages.description),
      illustration: 'illustrations/mainPtBr/sectionHero/heroSection.png',
      title: intl.formatMessage(heroSectionMessages.title),
    },
    importProcess: {
      buttonLink: `${reverseUrl.search()}?withPreliminaryAuth`,
      buttonText: intl.formatMessage(importProcessMessages.button),
      illustrations: [
        'illustrations/mainPtBr/sectionImportSteps/step1.svg',
        'illustrations/mainPtBr/sectionImportSteps/step2.svg',
        'illustrations/mainPtBr/sectionImportSteps/step3.svg',
        'illustrations/mainPtBr/sectionImportSteps/step4.svg',
        'illustrations/mainPtBr/sectionImportSteps/step5.svg',
        'illustrations/mainPtBr/sectionImportSteps/step6.svg',
        'illustrations/mainPtBr/sectionImportSteps/step7.svg',
        'illustrations/mainPtBr/sectionImportSteps/step8.svg',
      ],
      steps: [
        {
          description: intl.formatMessage(importProcessMessages.step1Description),
          title: intl.formatMessage(importProcessMessages.step1Title),
        },
        {
          description: intl.formatMessage(importProcessMessages.step2Description),
          title: intl.formatMessage(importProcessMessages.step2Title),
        },
        {
          description: intl.formatMessage(importProcessMessages.step3Description),
          title: intl.formatMessage(importProcessMessages.step3Title),
        },
        {
          description: intl.formatMessage(importProcessMessages.step4Description),
          title: intl.formatMessage(importProcessMessages.step4Title),
        },
        {
          description: intl.formatMessage(importProcessMessages.step5Description),
          title: intl.formatMessage(importProcessMessages.step5Title),
        },
        {
          description: intl.formatMessage(importProcessMessages.step6Description),
          title: intl.formatMessage(importProcessMessages.step6Title),
        },
        {
          description: intl.formatMessage(importProcessMessages.step7Description),
          title: intl.formatMessage(importProcessMessages.step7Title),
        },
        {
          description: intl.formatMessage(importProcessMessages.step8Description),
          title: intl.formatMessage(importProcessMessages.step8Title),
        },
      ],
      title: intl.formatMessage(importProcessMessages.title),
    },
    news: {
      cards: [
        ...articles.slice(0, 3).map((item): PromoNewsCardProps & {type: 'news'} => ({
          description: item.description,
          illustration: getSrcByWidth(item.imageMain.image.bundle, 500) || '',
          link: reverseUrl.blogPost({slug: item.slug}),
          target: '_blank',
          title: item.title,
          type: 'news',
        })),
        {
          actions: [
            <div className={styles.oneAction} key='blog'>
              <Button
                color='accent'
                href={reverseUrl.blog()}
                onClick={() => handleLinkClick(reverseUrl.blog())}
                size='large'
                target='_blank'
                type='link'
              >
                {intl.formatMessage(newsMessages.socialMediaBlogButton)}
              </Button>
            </div>,
          ],
          icon: <RecyclingIcon className={styles.icon} />,
          title: intl.formatMessage(newsMessages.socialMediaBlogTitle),
          type: 'socialMedia',
        },
        {
          className: styles.video,
          position: {
            columnEnd: 3,
            columnStart: 2,
            rowEnd: 2,
            rowStart: 2,
          },
          src: devicevarConfig?.news?.videoUrl || '',
          type: 'video',
        },
        {
          actions: [
            <Link
              className={styles.socialIcon}
              href={'https://www.linkedin.com/company/joomprobrasil'}
              key='linkedin'
              onClick={() => handleLinkClick('https://www.linkedin.com/company/joomprobrasil')}
              target='_blank'
              type='link'
            >
              <LinkedInIcon />
            </Link>,
            <Link
              className={styles.socialIcon}
              href={'https://www.facebook.com/joomprobrasil'}
              key='facebook'
              onClick={() => handleLinkClick('https://www.facebook.com/joomprobrasil')}
              target='_blank'
              type='link'
            >
              <FacebookIcon />
            </Link>,
            <Link
              className={styles.socialIcon}
              href={'https://www.instagram.com/joomprobrasil/'}
              key='instagram'
              onClick={() => handleLinkClick('https://www.instagram.com/joomprobrasil/')}
              target='_blank'
              type='link'
            >
              <InstagramIcon />
            </Link>,
          ],
          color: 'gray',
          icon: <EyeIcon />,
          title: intl.formatMessage(newsMessages.socialMediaFollowTitle),
          type: 'socialMedia',
        },
        {
          actions: [
            <div className={styles.oneAction} key='youtube'>
              <Button
                color='accent'
                href={'https://www.youtube.com/@JoomProBrasil'}
                onClick={() => handleLinkClick('https://www.youtube.com/@JoomProBrasil')}
                size='large'
                target='_blank'
                type='link'
              >
                {intl.formatMessage(newsMessages.socialMediaYoutubeButton)}
              </Button>
            </div>,
          ],
          icon: <StarIcon className={styles.icon} />,
          title: intl.formatMessage(newsMessages.socialMediaYoutubeTitle),
          type: 'socialMedia',
        },
      ],
      title: intl.formatMessage(newsMessages.title),
    },
    subscribe: {
      color: 'accent',
      onSubscribe: subscribeTask.perform,
      state: subscribeTask.state,
      subtitle: intl.formatMessage(subscribeMessages.subtitle),
      title: intl.formatMessage(subscribeMessages.title),
      withIllustration: false,
    },
  };
};
