import {CardInfo} from 'components/Main/CardInfo';
import {YouTubeVideoPlayer} from 'components/Main/YouTubeVideoPlayer';
import {buildMessage} from 'lib/intl';
import React from 'react';
import {useIntl} from 'react-intl';
import styles from './index.module.scss';

/* eslint-disable max-len */
const messages = {
  subtitle:
    'Мы сотрудничаем с независимыми компаниями по проверке качества. Их представители тестируют каждую партию согласно международным стандартам и составляют отчет о проверке — обычно он занимает до 40 страниц. Мы отправим заказ после того, как вы согласуете качество партии.',
  title: '⚠ Брак не пройдет',
};
/* eslint-enable max-len */

export function CardQuality(): React.ReactElement {
  const intl = useIntl();
  const media = (
    <YouTubeVideoPlayer className={styles.media} src='https://www.youtube.com/embed/C8eAr9bVeNE?mute=1&controls=2' />
  );

  return (
    <CardInfo
      align='left'
      border='white'
      color='black'
      media={media}
      subtitle={buildMessage(intl, messages.subtitle)}
      title={buildMessage(intl, messages.title)}
    />
  );
}
