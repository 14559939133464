import {Image} from 'components/Image';
import {Card} from 'components/Main/Card';
import {useAnalytics} from 'lib/analytics';
import {useAppEnv} from 'lib/appEnv';
import React, {useCallback} from 'react';
import {defineMessages, useIntl} from 'react-intl';
import {Button} from 'uikit/Button';
import styles from './index.module.scss';

const messages = defineMessages({
  button: {
    defaultMessage: 'Contact us',
    description: '[button] Self service main page. Contact us card: button',
  },
  title: {
    defaultMessage: 'Looking for supplier for your industry?',
    description: 'Self service main page. Contact us card: title',
  },
});

export type Props = {
  onContact?(): void;
};

export function CardContactUs({onContact}: Props): React.ReactElement {
  const intl = useIntl();
  const appEnv = useAppEnv();
  const analytics = useAnalytics();
  const handleContactUs = useCallback(() => {
    onContact?.();
    analytics.sendEvent({
      payload: {
        pageUrl: window.location.href,
        position: 'lookingForSupplierCard',
        source: appEnv.currentPageName,
      },
      type: 'contactUsClick',
    });
  }, [onContact]);

  return (
    <Card border='gray' color='white' padding='xs' rounded='m'>
      <div className={styles.container}>
        <Image className={styles.image} loading='lazy' src='/illustrations/mainPtBr/industry.png' />
        <div className={styles.content}>
          <div className={styles.title}>{intl.formatMessage(messages.title)}</div>
          <Button color='gray' onClick={handleContactUs} size='medium'>
            {intl.formatMessage(messages.button)}
          </Button>
        </div>
      </div>
    </Card>
  );
}
