/* eslint-disable max-len */
import {SectionMediaDataItem} from 'components/Main/SectionMedia/types';

export const data: SectionMediaDataItem[] = [
  {
    content: '“Китайские фабрики-мерчанты все больше интересуются российским рынком”',
    linkHref:
      'https://retail-loyalty.org/expert-forum/za-poslednie-polgoda-kitayskie-fabriki-merchanty-zainteresovalis-voprosami-raboty-s-rossiyskim-rynko/?sphrase_id=92619',
    linkText: 'Читать на Retail & Loyalty',
    logoSrc: '/illustrations/main/media/retailLoyalty.svg',
  },
  {
    content: '“96% предпринимателей, которые закупали товар в Китае, довольны опытом оптовых закупок”',
    linkHref:
      'https://new-retail.ru/novosti/retail/joompro_96_predprinimateley_kotorye_zakupali_tovar_v_kitae_dovolny_opytom_optovykh_zakupok8319/',
    linkText: 'Читать на New Retail',
    logoSrc: '/illustrations/main/media/nr.png',
  },
  {
    content: '“Подробный гайд о том, как возить товары из Китая для продажи на маркетплейсах”',
    linkHref: 'https://mpstats.io/media/business/novichkam/poisk-postavshchika-kitaj',
    linkText: 'Читать на mpstats journal',
    logoSrc: '/illustrations/main/media/mpstatsJournal.svg',
  },
  {
    content: '“Платформа позволит снизить пороги для входа в бизнес и увеличит число продавцов”',
    linkHref: 'https://www.kommersant.ru/doc/4815533',
    linkText: 'Подробнее в Ъ',
    logoSrc: '/illustrations/main/media/kommersant.svg',
  },
  {
    content: '“Все больше предпринимателей обращают внимание на китайский рынок”',
    linkHref: 'https://rb.ru/opinion/chinese-partners/',
    linkText: 'Читать на RB',
    logoSrc: '/illustrations/main/media/rb.svg',
  },
  {
    content: '“Китай – безальтернативный торговый партнер для российских предпринимателей”',
    linkHref: 'https://retail-loyalty.org/expert-forum/novye-osobennosti-zakupki-tovarov-v-kitae/?sphrase_id=57649',
    linkText: 'Читать на Retail & Loyalty',
    logoSrc: '/illustrations/main/media/retailLoyalty.svg',
  },
  {
    content: '“Проверяем продавца: как заказать качественный товар из Китая?”',
    linkHref: 'https://www.retail.ru/articles/proveryaem-prodavtsa-kak-zakazat-kachestvennyy-tovar-iz-kitaya/',
    linkText: 'Читать на Retail.ru',
    logoSrc: '/illustrations/main/media/retailRu.png',
  },
  {
    content: '“Клиенты ДжумПро могут получить кредит на закупку товара до 10 млн руб.”',
    linkHref: 'https://www.cnews.ru/news/line/2022-07-25_modulbank_i_joompro_pomogut',
    linkText: 'Читать на cnews',
    logoSrc: '/illustrations/main/media/cnews.png',
  },
];
