import cn from 'classnames';
import {Image} from 'components/Image';
import React from 'react';
import {useIntl} from 'react-intl';
import styles from './index.module.scss';
import {messages} from './messages';

export function Partners() {
  const intl = useIntl();
  return (
    <div className={styles.container}>
      <h2 className={styles.title}>{intl.formatMessage(messages.title)}</h2>
      <div className={styles.content}>
        <a
          href='https://epocanegocios.globo.com/empresas/noticia/2023/09/joompro-plataforma-europeia-para-importacao-de-produtos-da-china-expande-operacao-para-o-brasil.ghtml'
          target='_blank'
        >
          <Image className={styles.image} loading='lazy' src='/illustrations/media/negoclos.png' />
        </a>
        <a
          href='https://www.ecommercebrasil.com.br/noticias/joompro-lanca-catalogo-de-produtos-para-importacao'
          target='_blank'
        >
          <Image className={styles.image} loading='lazy' src='/illustrations/media/ecommerce.png' />
        </a>
        <a
          className={styles.smallImageWrapper}
          href='https://www.terra.com.br/economia/dinheiro-em-acao/joompro-plataforma-europeia-de-importacao-de-produtos-da-china-expande-operacoes-para-o-brasil,6dfa9ed067b9d95798644c4811f94b08m277eii8.html'
          target='_blank'
        >
          <Image className={cn(styles.image, styles.smallImage)} loading='lazy' src='/illustrations/media/terra.png' />
        </a>
        <a
          href='https://dcomercio.com.br/publicacao/s/joompro-entra-na-disputa-pelo-mercado-de-importacao-no-brasil'
          target='_blank'
        >
          <Image className={styles.image} loading='lazy' src='/illustrations/media/dirao.png' />
        </a>
        <a
          href='https://economia.uol.com.br/noticias/reuters/2024/04/12/joompro-lanca-plataforma-de-importacao-de-produtos-chineses-no-brasil.htm'
          target='_blank'
        >
          <Image className={cn(styles.image, styles.smallImage)} loading='lazy' src='/illustrations/media/uol.png' />
        </a>
      </div>
    </div>
  );
}
