import {Card} from 'components/Main/Card';
import {Typography} from 'components/Main/Typography';
import {buildMessage} from 'lib/intl';
import React from 'react';
import {useIntl} from 'react-intl';
import styles from './index.module.scss';

/* eslint-disable max-len */
const messages = [
  'ДжумПро основан в 2021 и сегодня это независимый сервис, выросший на опыте и экспертизе команды маркетплейса Joom. Наша международная команда экспертов, распределенная в основном между офисами в Армении, России и Китае, переняла лучшие практики е-ком площадки в работе с китайскими поставщиками и клиентском сервисе.',
  'Это помогло ДжумПро занять активную позицию на российском рынке сервисов для селлеров и выработать комплексное предложение, позволяющее предпринимателям закрыть сразу все потребности в процессе импорта товаров из Китая.',
];
/* eslint-enable max-len */

export function CardAbout(): React.ReactElement {
  const intl = useIntl();

  return (
    <Card color='gray' padding={['l', 'xl']}>
      <div className={styles.content}>
        {messages.map((message, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Typography key={index} kind='promo' size='l' variant='text'>
            {buildMessage(intl, message)}
          </Typography>
        ))}
      </div>
    </Card>
  );
}
