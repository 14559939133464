import {Category} from 'lib/category/types';
import styles from './index.module.scss';
import {Item} from './Item';
import {ItemShowAll} from './ItemShowAll';

export type Props = {
  categories: Category[];
};

const categoryIdsOrder = [
  '1473502936226769818-70-2-118-2760164976', // Household Products
  '1473502940600254907-239-2-118-2183002000', // Health & Beauty
  '1473502935479416415-109-2-118-770440083', // Electronics
  '1481193541758566184-236-2-26341-2575447955', // Hobbies & Creativity
  '1473502940140554914-82-2-118-181841690', // Women's Clothing & Accessories
  '1473502943025251808-217-2-118-511595927', // Men's Clothing & Accessories
  '1473502946841916116-100-2-118-270970625', // Suitcases & Bags
];

export function Categories({categories}: Props): React.ReactElement {
  return (
    <div className={styles.container}>
      {categories
        .filter((item) => categoryIdsOrder.includes(item.id))
        .sort((itemA, itemB) => categoryIdsOrder.indexOf(itemA.id) - categoryIdsOrder.indexOf(itemB.id))
        .map((category) => (
          <div className={styles.item} key={category.id}>
            <Item category={category} />
          </div>
        ))}
      <div className={styles.expander}>
        <ItemShowAll />
      </div>
    </div>
  );
}
