import React from 'react';
import styles from './index.module.scss';

export type Props = {
  image: React.ReactNode;
  name: string;
};

export function ItemContent({name, image}: Props): React.ReactElement {
  return (
    <div className={styles.container}>
      {image && <div className={styles.figure}>{image}</div>}
      <div className={styles.name} title={name}>
        {name}
      </div>
    </div>
  );
}
