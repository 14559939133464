import {Image} from 'components/Image';
import {Reducer} from 'components/Layout/Reducer';
import {ButtonLink} from 'components/Main/Button';
import {Card} from 'components/Main/Card';
import {FadeIn} from 'components/Main/FadeIn';
import {FADE_IN_THRESHOLD, FADE_IN_ROOT_MARGIN} from 'components/Main/lib';
import {NavigationId} from 'components/Main/lib/types';
import {Typography} from 'components/Main/Typography';
import {reverseUrl} from 'lib/router';
import {TestIdProp} from 'lib/testing/types';
import React from 'react';
import styles from './index.module.scss';

const messages = {
  button: 'Подробнее',
  // eslint-disable-next-line max-len
  subtitle:
    'Получите выплату в размере 5% от суммы первого заказа нового клиента. Присоединиться к программе может как юридическое, так и физическое лицо.',
  title: 'Зарабатывайте\u00a0деньги на\u00a0реферальной программе',
};

export type SectionReferralTestId = unknown;

type Props = TestIdProp<SectionReferralTestId>;

export function SectionReferral({testId}: Props): React.ReactElement {
  return (
    <div className={styles.container} data-test-id={testId} id={NavigationId.REFERRAL}>
      <Reducer>
        <FadeIn rootMargin={FADE_IN_ROOT_MARGIN} threshold={FADE_IN_THRESHOLD}>
          <Card color='black' overflow='hidden' padding='l'>
            <div className={styles.card}>
              <div className={styles.background} />
              <div className={styles.content}>
                <div className={styles.body}>
                  <Typography color='white' kind='promo' variant='h3'>
                    {messages.title}
                  </Typography>
                  <Typography color='white' kind='promo' size='l' variant='text'>
                    {messages.subtitle}
                  </Typography>
                </div>
                <div className={styles.actions}>
                  <ButtonLink color='white' href={reverseUrl.referral()} rounded size='xl'>
                    {messages.button}
                  </ButtonLink>
                </div>
              </div>
              <div className={styles.figure}>
                <Image className={styles.image} loading='lazy' src='/illustrations/main/referral.svg' />
              </div>
            </div>
          </Card>
        </FadeIn>
      </Reducer>
    </div>
  );
}
