import {Footer} from 'components/Main/Footer';
import {MainLayout} from 'components/Main/Layout';
import {MainContext} from 'components/Main/lib';
import {Navigation} from 'components/Main/Navigation';
import {ConferenceTopBanner} from 'components/MainForRu/ConferenceTopBanner';
import {SectionAbout} from 'components/MainForRu/SectionAbout';
import {SectionMedia} from 'components/MainForRu/SectionMedia';
import {SectionPartners} from 'components/MainForRu/SectionPartners';
import {SectionPayments} from 'components/MainForRu/SectionPayments';
import {SectionQuality} from 'components/MainForRu/SectionQuality';
import {SectionReferral} from 'components/MainForRu/SectionReferral';
import {SectionReviews} from 'components/MainForRu/SectionReviews';
import {SectionServices} from 'components/MainForRu/SectionServices';
import {SectionWelcome} from 'components/MainForRu/SectionWelcome';
import React, {useCallback, useContext} from 'react';

export function MainRu(): React.ReactElement {
  const {onOpenConsultationDialog} = useContext(MainContext);
  const handleOpenConsultationDialog = useCallback((section: string) => {
    onOpenConsultationDialog?.({section});
  }, []);

  return (
    <MainLayout
      footer={<Footer onConnect={handleOpenConsultationDialog} />}
      navigation={
        <>
          <ConferenceTopBanner />
          <Navigation onConnect={handleOpenConsultationDialog} theme='black' />
        </>
      }
    >
      <SectionWelcome />
      <SectionPayments />
      <SectionServices />
      <SectionQuality />
      <SectionAbout />
      <SectionReviews />
      <SectionReferral />
      <SectionPartners />
      <SectionMedia />
    </MainLayout>
  );
}
