import {ButtonLink} from 'components/Main/Button';
import {useStore} from 'lib/effector';
import {$serverDate} from 'models/serverDate';
import React, {useMemo} from 'react';
import IconBox from './box.svg';
import IconFactory from './factory.svg';
import styles from './index.module.scss';
import IconPlane from './plane.svg';

const EXTERNAL_LINK_HREF = 'https://conf2024.joompro.ru/?utm_source=landing';
const SHOW_BANNER_UNTIL_DATE = new Date('2024-09-16T19:00:00.000Z');

export function ConferenceTopBanner(): React.ReactElement | null {
  const serverDate = useStore($serverDate);
  const showBanner = useMemo(() => new Date(serverDate) < SHOW_BANNER_UNTIL_DATE, [serverDate]);

  if (showBanner) {
    return (
      <div className={styles.banner}>
        <div className={styles.icons}>
          <IconPlane />
          <IconBox />
          <IconFactory />
        </div>
        <span className={styles.text}>Конференция «Успешная закупка в Китае»</span>
        <ButtonLink color='black' href={EXTERNAL_LINK_HREF} rounded size='sm' target='_blank'>
          Подробнее
        </ButtonLink>
      </div>
    );
  }

  return null;
}
