import {Reducer} from 'components/Layout/Reducer';
import {Button} from 'components/Main/Button';
import {MainContext} from 'components/Main/lib';
import {NavigationId} from 'components/Main/lib/types';
import {Typography} from 'components/Main/Typography';
import {buildMessage} from 'lib/intl';
import {TestIdProp} from 'lib/testing/types';
import React, {useContext, useCallback} from 'react';
import {useIntl} from 'react-intl';
import {Cards} from './Cards';
import styles from './index.module.scss';
import {Offers} from './Offers';

export type SectionWelcomeTestId = unknown;

type Props = TestIdProp<SectionWelcomeTestId>;

/* eslint-disable max-len */
const messages = {
  button: 'Получить консультацию',
  subtitle:
    'Знаем толк в закупках и логистике. Найдем и доставим на ваш склад товары от проверенных производителей из Китая',
  title_part1: 'Занимайтесь бизнесом ',
  title_part2: 'закупкой займемся мы',
};
/* eslint-enable max-len */

export function SectionWelcome({testId}: Props): React.ReactElement {
  const intl = useIntl();
  const {onOpenConsultationDialog} = useContext(MainContext);
  const handleOpenConsultationDialog = useCallback(() => {
    onOpenConsultationDialog?.();
  }, [onOpenConsultationDialog]);

  return (
    <div className={styles.container} data-test-id={testId}>
      <Reducer>
        <div className={styles.content}>
          <header className={styles.header}>
            <Typography color='white' kind='promo' variant='h1'>
              <div className={styles.title}>
                <div>♥</div>
                <div className={styles.titleText}>
                  <span>{buildMessage(intl, messages.title_part1)}</span>
                  <span className={styles.highlighted}>{buildMessage(intl, messages.title_part2)}</span>
                </div>
              </div>
            </Typography>
            <Typography color='white' component='div' kind='promo' size='l' variant='subtitle'>
              <span className={styles.subtitle}>{buildMessage(intl, messages.subtitle)}</span>
            </Typography>
            <div className={styles.actions}>
              <Button color='red' onClick={handleOpenConsultationDialog} rounded size='xl'>
                {buildMessage(intl, messages.button)}
              </Button>
            </div>
          </header>
          <div className={styles.offer} id={NavigationId.SOLUTIONS}>
            <Offers />
            <Cards />
          </div>
        </div>
      </Reducer>
    </div>
  );
}
