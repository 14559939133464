import {ArticleFactory} from 'components/ArticleFactory';
import React from 'react';
import {defineMessages, useIntl} from 'react-intl';
import {Dialog, DialogContent, DialogHeader} from 'uikit/Dialog';
import {data} from './data';
import styles from './index.module.scss';

const messages = defineMessages({
  title: {
    defaultMessage: 'Import today and pay later!*',
    description: '[title] Self service main page. Financing dialog: title.',
  },
});

export type Props = {
  onClose(): void;
};

export function FinancingDialog({onClose}: Props): React.ReactElement {
  const intl = useIntl();

  return (
    <Dialog onClose={onClose} width='400px'>
      <DialogHeader withCloseButton>
        <div className={styles.title}>{intl.formatMessage(messages.title)}</div>
      </DialogHeader>
      <DialogContent noTopSpacing>
        <div className={styles.content}>
          <ArticleFactory data={data} />
        </div>
      </DialogContent>
    </Dialog>
  );
}
