import {Reducer} from 'components/Layout/Reducer';
import {Category} from 'lib/category/types';
import {Promotion} from 'lib/promotion/types';
import React, {ForwardedRef} from 'react';
import {SearchProps} from '../types';
import {BlockCatalog} from './BlockCatalog';
import {BlockPossibilities} from './BlockPossibilities';
import {BlockProducts} from './BlockProducts';
import styles from './index.module.scss';

type Props = SearchProps & {
  blockCatalogRef?: ForwardedRef<HTMLDivElement>;
  categories: Category[];
  onContactUs?(): void;
  onFinancing(): void;
  promotions: Promotion[];
};

export function SectionWelcome({
  blockCatalogRef,
  promotions,
  categories,
  onSuggest,
  onContactUs,
  onFinancing,
  searchSuggestions,
  searchSuggestionsPhrase,
}: Props): React.ReactElement {
  return (
    <div className={styles.container}>
      <Reducer>
        <div className={styles.content}>
          <BlockCatalog
            categories={categories}
            onContactUs={onContactUs}
            onSuggest={onSuggest}
            ref={blockCatalogRef}
            searchSuggestions={searchSuggestions}
            searchSuggestionsPhrase={searchSuggestionsPhrase}
          />
          <BlockProducts promotions={promotions} />
          <BlockPossibilities onFinancing={onFinancing} />
        </div>
      </Reducer>
    </div>
  );
}
