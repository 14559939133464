import {CardInfo} from 'components/Main/CardInfo';
import {buildMessage} from 'lib/intl';
import React from 'react';
import {useIntl} from 'react-intl';

/* eslint-disable max-len */
const messages = {
  subtitle:
    'Тысячи наших проверенных поставщиков предложат товары высокого качества под ваш запрос, а команда экспертов ДжумПро поможет приобрести партию на выгодных условиях',
  title: 'Соответствует самым высоким ожиданиям',
};
/* eslint-enable max-len */

export function CardGaranties(): React.ReactElement {
  const intl = useIntl();

  return (
    <CardInfo
      align='left'
      color='gray'
      subtitle={buildMessage(intl, messages.subtitle)}
      title={buildMessage(intl, messages.title)}
    />
  );
}
