import {NavigationId} from 'components/Main/lib/types';
import {Section} from 'components/Main/Section';
import {TestIdProp} from 'lib/testing/types';
import React from 'react';
import {BlockCustomisation} from './BlockCustomisation';
import {BlockDocuments} from './BlockDocuments';
import styles from './index.module.scss';

export type SectionServicesTestId = unknown;

type Props = TestIdProp<SectionServicesTestId>;

export function SectionServices({testId}: Props): React.ReactElement {
  return (
    <Section color='white' id={NavigationId.SERVICE} testId={testId}>
      <div className={styles.content}>
        <BlockDocuments />
        <BlockCustomisation />
      </div>
    </Section>
  );
}
