import {CardMedia} from 'components/Main/CardMedia';
import React from 'react';

export function CardVerify(): React.ReactElement {
  return (
    <CardMedia
      badge='☺ проверка качества'
      color='black'
      illustration='/illustrations/main2/sectionQuality/figure_1.jpeg'
    />
  );
}
