import {Card} from 'components/Main/Card';
import React from 'react';
import {defineMessages, useIntl} from 'react-intl';
import {CardPayLater} from '../../components/CardPayLater';
import {CardRequestSamples} from '../../components/CardRequestSamples';
import {Possibilities} from '../../components/Possibilities';
import styles from './index.module.scss';

const messages = defineMessages({
  title: {
    defaultMessage: 'Reasons to import from China with JoomPro',
    description: 'Self service main page. Possibilities block: title',
  },
});

type Props = {
  onFinancing(): void;
};

export function BlockPossibilities({onFinancing}: Props): React.ReactElement {
  const intl = useIntl();

  return (
    <div className={styles.container}>
      <Card color='black' gridArea='title' padding='xs' rounded='m'>
        <div className={styles.title}>{intl.formatMessage(messages.title)}</div>
      </Card>
      <Possibilities gridArea='possibilities' />
      <CardPayLater gridArea='payLater' onClick={onFinancing} />
      <CardRequestSamples gridArea='samples' />
    </div>
  );
}
