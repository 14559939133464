import {Image} from 'components/Image';
import {CardInfo} from 'components/Main/CardInfo';
import {buildMessage} from 'lib/intl';
import React from 'react';
import {useIntl} from 'react-intl';
import styles from './index.module.scss';

/* eslint-disable max-len */
const messages = {
  subtitle:
    'Вместе с товаром мы предоставим всю необходимую документацию для продаж на маркетплейсах и в магазинах: инструкции на русском языке, сертификаты соответствия, маркировки ЕАЭС и “Честный знак”',
  title: 'Готов к продаже',
};
/* eslint-enable max-len */

export function CardDocuments(): React.ReactElement {
  const intl = useIntl();
  const media = (
    <Image className={styles.image} loading='lazy' src='/illustrations/mainRu/sectionServices/figure_1.svg' />
  );

  return (
    <CardInfo
      border='black'
      color='white'
      media={media}
      subtitle={buildMessage(intl, messages.subtitle)}
      title={buildMessage(intl, messages.title)}
    />
  );
}
