import {KallaxLayout} from 'components/Main/Layout/Kallax';
import {Typography} from 'components/Main/Typography';
import {buildMessage} from 'lib/intl';
import React from 'react';
import {useIntl} from 'react-intl';
import {Action} from './Action';
import {CardDocuments} from './CardDocuments';
import {CardFactory} from './CardFactory';
import {CardGaranties} from './CardGaranties';
import styles from './index.module.scss';

const messages = {
  title: 'Товар, который нужен именно вам',
};

export function BlockDocuments(): React.ReactElement {
  const intl = useIntl();

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <Typography kind='promo' variant='h2'>
          {buildMessage(intl, messages.title)}
        </Typography>
      </div>
      <KallaxLayout
        action={<Action />}
        blockA={<CardDocuments />}
        blockB={<CardFactory />}
        blockC={<CardGaranties />}
      />
    </div>
  );
}
