import {Reducer} from 'components/Layout/Reducer';
import React from 'react';
import {Benefits} from '../components/Benefits';
import {CardContactExpert} from '../components/CardContactExpert';
import {CardFinancingTrade} from '../components/CardFinancingTrade';
import {CardIndustry} from '../components/CardIndustry';
import styles from './index.module.scss';

type Props = {
  onContactUs?(): void;
  onFinancing(): void;
};

export function SectionBenefits({onFinancing, onContactUs}: Props): React.ReactElement {
  return (
    <div className={styles.container}>
      <Reducer>
        <div className={styles.content}>
          <CardIndustry gridArea='industry' />
          <Benefits gridArea='benefits' />
          <CardFinancingTrade gridArea='financing' onClick={onFinancing} />
          <CardContactExpert gridArea='expert' onContact={onContactUs} />
        </div>
      </Reducer>
    </div>
  );
}
