import React from 'react';
import {data} from './data';
import styles from './index.module.scss';
import {Offer} from './Offer';

export function Offers(): React.ReactElement {
  return (
    <div className={styles.container}>
      {data.map((dataItem) => (
        <Offer button={dataItem.button} illustration={dataItem.illustration} key={dataItem.id} title={dataItem.title} />
      ))}
    </div>
  );
}
