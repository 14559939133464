import cn from 'classnames';
import {Image} from 'components/Image';
import {Reducer} from 'components/Layout/Reducer';
import {useAnalytics} from 'lib/analytics';
import {useAppEnv} from 'lib/appEnv';
import React, {useCallback, useMemo} from 'react';
import {Button} from 'uikit/Button';
import styles from './index.module.scss';

export type Step = {
  description: string;
  title: string;
};

export type Props = {
  buttonLink?: string;
  buttonText?: string;
  illustrations: string[];
  parts?: number;
  steps: Step[];
  title: string;
};

const Steps = ({
  steps,
  illustrations,
  reverse,
  partNumber,
}: Omit<Props, 'title'> & {partNumber: number; reverse: boolean}) => {
  return (
    <div className={styles.part}>
      <div className={cn(styles.steps, reverse ? styles.column2 : styles.column1)}>
        {steps.map((step, indx) => (
          <div className={styles.stepContent} key={indx}>
            <div className={styles.stepNumber}>{indx + partNumber}</div>
            <div className={styles.devieder} />
            <div className={styles.stepTitle}>{step.title}</div>
            <div className={styles.stepDescription}>{step.description}</div>
          </div>
        ))}
      </div>
      <div className={cn(styles.illustrations, reverse ? styles.column1 : styles.column2)}>
        {illustrations.map((illustration, indx) => (
          <div className={styles.imageBox} key={indx}>
            <Image className={styles.image} src={illustration} />
          </div>
        ))}
      </div>
    </div>
  );
};

export const ImportProcess = ({buttonText, buttonLink, steps, illustrations, title, parts = 2}: Props) => {
  const analytics = useAnalytics();
  const appEnv = useAppEnv();
  const partLength = steps.length / parts;

  const stepsByParts = useMemo(() => {
    const partsArray: {illustrationParts: string[]; stepParts: Step[]}[] = [];
    for (let i = 0; i < parts; i++) {
      partsArray.push({
        illustrationParts: illustrations.slice(i * partLength, i * partLength + partLength),
        stepParts: steps.slice(i * partLength, i * partLength + partLength),
      });
    }

    return partsArray;
  }, [parts, steps, illustrations, partLength]);

  const handleLinkClick = useCallback(() => {
    buttonLink &&
      analytics.sendEvent({
        payload: {
          pageUrl: window.location.href,
          source: 'importProcess',
          targetUrl: buttonLink,
        },
        type: 'navigationClick',
      });
  }, [analytics.sendEvent, appEnv.currentPageName, buttonLink]);

  return (
    <div className={styles.background}>
      <Reducer>
        <div className={styles.container}>
          <span className={styles.title}>{title}</span>
          <div className={styles.content}>
            {stepsByParts.map(({stepParts, illustrationParts}, index) => (
              <Steps
                illustrations={illustrationParts}
                key={index}
                partNumber={index * partLength + 1}
                reverse={index % 2 !== 0}
                steps={stepParts}
              />
            ))}
            {buttonText && (
              <div className={styles.button}>
                <Button color='accent' href={buttonLink || ''} onClick={handleLinkClick} size='large' type='link'>
                  {buttonText}
                </Button>
              </div>
            )}
          </div>
        </div>
      </Reducer>
    </div>
  );
};
