import cnBind from 'classnames/bind';
import {useCallback} from 'react';
import styles from './index.module.scss';

const cn = cnBind.bind(styles);

export type Tab = {
  id: string;
  label: React.ReactNode;
};

export type PromoTabsProps = {
  activeTabId: string;
  onActiveTabIdChange: (id: string) => void;
  tabs: Tab[];
};

export type PromoTabProps = {
  id: string;
  label: React.ReactNode;
  onClick: (id: string) => void;
  selected: boolean;
};

const PromoTab = ({label, id, onClick, selected}: PromoTabProps) => {
  const handleClick = useCallback(() => onClick(id), [id, onClick]);
  return (
    <button className={cn('button', {selected})} onClick={handleClick} type='button'>
      {label}
    </button>
  );
};

export const PromoTabs = ({tabs, onActiveTabIdChange, activeTabId}: PromoTabsProps) => {
  return (
    <div className={cn('root')}>
      {tabs.map((tab) => (
        <PromoTab
          id={tab.id}
          key={tab.id}
          label={tab.label}
          onClick={onActiveTabIdChange}
          selected={tab.id === activeTabId}
        />
      ))}
    </div>
  );
};
