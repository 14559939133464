import {AuthOrigin, AuthViewName} from 'components/Auth/types';
import {Layout} from 'components/Layout';
import {MainDecorator, MainDecoratorProps, scrollToByHash} from 'components/Main/lib';
import {useAnalytics} from 'lib/analytics';
import {useEvent, useStore} from 'lib/effector';
import {useLanguage} from 'lib/language/hooks';
import {Language} from 'lib/language/types';
import {signOutFx} from 'models/auth';
import {openAuthDialog} from 'models/auth/dialog';
import {openConsultationDialog} from 'models/consultation';
import {openContactUsDialog} from 'models/selfService/contactUs';
import {$user} from 'models/user';
import React, {useMemo, useCallback, useEffect} from 'react';
import {MainPtBr} from './MainPtBr';
import {MainRu} from './MainRu';

function MainInner(): React.ReactElement {
  const language = useLanguage();

  switch (language) {
    case Language.RU:
      return <MainRu />;
    case Language.PT_BR:
    default:
      return <MainPtBr />;
  }
}

export function MainPage(): React.ReactElement {
  const user = useStore($user);
  const handleSignOut = useEvent(signOutFx);
  const analytics = useAnalytics();
  const language = useLanguage();
  const handleOpenConsultationDialog = useEvent(openConsultationDialog);
  const handleOpenContactUsDialog = useEvent(openContactUsDialog);
  const handleOpenAuthDialogEvent = useEvent(openAuthDialog);
  const handleOpenAuthDialog = useCallback(() => {
    handleOpenAuthDialogEvent({
      origin: AuthOrigin.SELF_SERVICE,
      view: AuthViewName.SIGN_IN_BY_PHONE,
    });
  }, [handleOpenAuthDialogEvent, language]);

  const profile = useMemo<MainDecoratorProps['profile']>(
    () => ({
      onSignIn: handleOpenAuthDialog,
      onSignOut: handleSignOut,
      user,
    }),
    [user, handleSignOut, handleOpenAuthDialog],
  );

  useEffect(() => {
    if (window.location.hash) {
      scrollToByHash(window.location.hash);
    }

    analytics.sendEvent({
      payload: {
        pageUrl: window.location.href,
      },
      type: 'mainOpen',
    });
  }, []);

  return (
    <Layout>
      <MainDecorator
        onOpenConsultationDialog={handleOpenConsultationDialog}
        onOpenContactUsDialog={handleOpenContactUsDialog}
        profile={profile}
      >
        <MainInner />
      </MainDecorator>
    </Layout>
  );
}
