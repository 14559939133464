import {isContentListProductItem} from 'lib/contentList';
import {Promotion} from 'lib/promotion/types';
import keyBy from 'lodash/keyBy';
import {useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import {Button} from 'uikit/Button';
import {PromoTabs, Tab} from 'uikit/PromoTabs';
import styles from './index.module.scss';
import {messages} from './messages';
import {Products} from './Products';

export type Props = {
  buttonLink: string;
  promotions: Promotion[];
  shownProductsCount?: number;
};

export function BlockProducts({promotions, shownProductsCount = 12, buttonLink}: Props): React.ReactElement {
  const intl = useIntl();
  const tabs: Tab[] = useMemo(
    () =>
      promotions
        .filter((item) => !!item.items.some(isContentListProductItem))
        .map((item) => ({id: item.promotionId, label: item.title})),
    [promotions],
  );
  const promotionById = useMemo(() => keyBy(promotions, (item) => item.promotionId), [promotions]);
  const [activeTabId, setActiveTabId] = useState(tabs[0]?.id);
  const activePromotion = promotionById[activeTabId];

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <PromoTabs activeTabId={activeTabId} onActiveTabIdChange={setActiveTabId} tabs={tabs} />
        <Products
          items={activePromotion?.items.slice(0, shownProductsCount)}
          key={activePromotion?.promotionId}
          position={activePromotion?.title || ''}
          promotionId={activePromotion?.promotionId}
        />
      </div>
      <div className={styles.button}>
        <Button color='black' href={buttonLink} size='large' type='link'>
          {intl.formatMessage(messages.button)}
        </Button>
      </div>
    </div>
  );
}
