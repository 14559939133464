import {Image} from 'components/Image';
import {Card} from 'components/Main/Card';
import {useAnalytics} from 'lib/analytics';
import {useAppEnv} from 'lib/appEnv';
import React, {useCallback} from 'react';
import {defineMessages, useIntl} from 'react-intl';
import {Button} from 'uikit/Button';
import styles from './index.module.scss';

const messages = defineMessages({
  button: {
    defaultMessage: 'Talk to an expert',
    description: '[button] Self service main page. Card contact expert: action button',
  },
  text: {
    defaultMessage: 'Contact our dedicated support!',
    description: 'Self service main page. Card contact expert: text',
  },
});

export type Props = {
  gridArea?: string;
  onContact?(): void;
};

export function CardContactExpert({onContact, gridArea}: Props): React.ReactElement {
  const intl = useIntl();
  const appEnv = useAppEnv();
  const analytics = useAnalytics();
  const handleContactUs = useCallback(() => {
    onContact?.();
    analytics.sendEvent({
      payload: {
        pageUrl: window.location.href,
        position: 'talkToAnExpertCard',
        source: appEnv.currentPageName,
      },
      type: 'contactUsClick',
    });
  }, [onContact]);

  return (
    <Card color='black' gridArea={gridArea} padding='xs' rounded='m'>
      <div className={styles.container}>
        <div className={styles.content}>
          <Image loading='lazy' src='/illustrations/mainPtBr/talk-to-expert.svg' />
          <div className={styles.text}>{intl.formatMessage(messages.text)}</div>
          <div className={styles.actions}>
            <Button color='accent' onClick={handleContactUs} size='large'>
              {intl.formatMessage(messages.button)}
            </Button>
          </div>
        </div>
      </div>
    </Card>
  );
}
