import {Icon as CategoryIcon} from 'components/Categories/Icon';
import {Link} from 'components/Link';
import {useAnalytics} from 'lib/analytics';
import {Category} from 'lib/category/types';
import {reverseUrl} from 'lib/router';
import React, {useCallback} from 'react';
import {ItemContent} from '../ItemContent';

export type Props = {
  category: Category;
};

export function Item({category}: Props): React.ReactElement {
  const analytics = useAnalytics();
  const handleClick = useCallback(() => {
    analytics.sendEvent({
      payload: {
        categoryId: category.id,
        pageUrl: window.location.href,
        position: 'mainBody',
        source: 'firstScreen',
      },
      type: 'categoryClick',
    });
  }, [analytics.sendEvent, category.id]);

  return (
    <Link href={reverseUrl.search({categoryId: category.id})} onClick={handleClick}>
      <ItemContent image={<CategoryIcon category={category} />} name={category.name} />
    </Link>
  );
}
