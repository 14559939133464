import {Image} from 'components/Image';
import {Card} from 'components/Main/Card';
import {Typography} from 'components/Main/Typography';
import {buildMessage} from 'lib/intl';
import React from 'react';
import {useIntl} from 'react-intl';
import styles from './index.module.scss';

/* eslint-disable max-len */
const messages = {
  subtitle:
    'Мы прописываем сроки доставки в договоре и постоянно информируем о статусе партии: где находится товар и когда прибудет на ваш склад.',
  title: 'Доставка под контролем',
};
/* eslint-enable max-len */

export function CardControl(): React.ReactElement {
  const intl = useIntl();

  return (
    <Card border='white' color='black' padding={['s', 'xl']}>
      <div className={styles.content}>
        <Image className={styles.image} loading='lazy' src='/illustrations/mainRu/sectionQuality/figure_3.png' />
        <div className={styles.info}>
          <Typography color='white' kind='promo' variant='h3'>
            {buildMessage(intl, messages.title)}
          </Typography>
          <Typography color='white' kind='promo' size='l' variant='text'>
            {buildMessage(intl, messages.subtitle)}
          </Typography>
        </div>
      </div>
    </Card>
  );
}
