/* eslint-disable no-irregular-whitespace */

import {Image} from 'components/Image';
import {ButtonLink} from 'components/Main/Button';
import {Section} from 'components/Main/Section';
import {Typography} from 'components/Main/Typography';
import {DataItem} from 'components/PartnersRu/SectionOffers/Card/types';
import {data} from 'components/PartnersRu/SectionOffers/data';
import {buildMessage} from 'lib/intl';
import {reverseUrl} from 'lib/router';
import {TestIdProp} from 'lib/testing/types';
import React from 'react';
import {defineMessage, useIntl} from 'react-intl';
import styles from './index.module.scss';

export type SectionServicesTestId = unknown;

type Props = TestIdProp<SectionServicesTestId>;

const message = defineMessage({
  // eslint-disable-next-line max-len
  defaultMessage: 'And {restPartnersCount, plural, one {# more offer} other {# more offers}} from our partners',
  description: 'Home page. Partner offers section. Partner description',
});

const DATA = ['main-partners-partner-xway', 'main-partners-partner-b2basket']
  .map((id) => data.find((item) => item.id === id))
  .filter((item): item is DataItem => item !== undefined);

const REST_PARTNERS_COUNT = data.length - DATA.length;

export function SectionPartners({testId}: Props): React.ReactElement {
  const intl = useIntl();

  return (
    <Section color='white' testId={testId}>
      <div className={styles.content}>
        <div className={styles.header}>
          <Typography kind='promo' variant='h2'>
            Специальные предложения партнеров
          </Typography>
          <Typography alternative kind='promo' size='xl' variant='subtitle'>
            Собираем для вас выгодные условия и предложения от наших партнеров
          </Typography>
        </div>
        <div className={styles.partners}>
          {DATA.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className={styles.card} key={index}>
              <div className={styles.cardLogoWrap}>
                <Image className={styles.cardLogo} src={item.illustration} />
              </div>
              <Typography kind='promo' variant='text'>
                {buildMessage(intl, item.descriptionPreview)}
              </Typography>
            </div>
          ))}
          <div className={styles.card}>
            <div className={styles.cardLogoWrap}>
              <Image className={styles.cardLogo} src='/illustrations/mainPartners/restPartners3Logo.png' />
              <div className={styles.cardLogoCount}>+{REST_PARTNERS_COUNT - 3}</div>
            </div>
            <Typography kind='promo' variant='text'>
              {buildMessage(intl, message, {restPartnersCount: REST_PARTNERS_COUNT})}
            </Typography>
          </div>
        </div>
        <div className={styles.actions}>
          <ButtonLink href={reverseUrl.partners()} rounded target='_blank'>
            Перейти к предложениям
          </ButtonLink>
        </div>
      </div>
    </Section>
  );
}

/* eslint-enable no-irregular-whitespace */
