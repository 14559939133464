import {DataItem, DataType} from 'components/ArticleFactory/types';
import {defineMessage} from 'react-intl';

/* eslint-disable max-len */
export const data: DataItem[] = [
  {
    type: DataType.PARAGRAPH,
    value: defineMessage({
      defaultMessage:
        'We have <colorAccent>partnerships with financial institutions that offer credit solutions</colorAccent> for those who want to import and boost their businesses but need financial support.',
      description: 'Self service main page. Content message.',
    }),
  },
  {
    type: DataType.PARAGRAPH,
    value: defineMessage({
      defaultMessage:
        "When we send you our quote, we will also forward proposals of payment conditions elaborated by our financial partners. Therefore, you'll be able to:",
      description: 'Self service main page. Content message.',
    }),
  },
  {
    type: DataType.UNORDERED_LIST,
    value: [
      defineMessage({
        defaultMessage: 'Evaluate the best payment option;',
        description: 'Self service main page. Content message.',
      }),
      defineMessage({
        defaultMessage: 'Request a more detailed credit analysis for your CNPJ;',
        description: 'Self service main page. Content message.',
      }),
      defineMessage({
        defaultMessage:
          'After credit approval, acquire the necessary amount to complete your importation with JoomPro.',
        description: 'Self service main page. Content message.',
      }),
    ],
  },
  {
    options: {
      color: 'secondary',
    },
    type: DataType.PARAGRAPH,
    value: defineMessage({
      defaultMessage:
        '*The financial products offered by our partners are subject to a credit evaluation conducted without interference from JoomPro.',
      description: 'Self service main page. Content message.',
    }),
  },
];
