import {CardMedia} from 'components/Main/CardMedia';
import {HyllisLayout} from 'components/Main/Layout/Hyllis';
import React from 'react';

export function CardGallery(): React.ReactElement {
  return (
    <HyllisLayout
      blockA={
        <CardMedia
          badge='☺ образцы продукции'
          badgeAlign='right-bottom'
          color='gray'
          illustration='/illustrations/main2/sectionAbout/figure_2.png'
          illustrationAlign='bottom'
        />
      }
      blockB={
        <CardMedia
          badge='☺ погрузка товара'
          badgeAlign='left-top'
          color='gray'
          illustration='/illustrations/main2/sectionAbout/figure_3.png'
        />
      }
      blockC={
        <CardMedia
          badge='☺ производство'
          badgeAlign='left-top'
          color='gray'
          illustration='/illustrations/main2/sectionAbout/figure_4.png'
        />
      }
      blockD={
        <CardMedia
          badge='☺ наш офис в Китае'
          badgeAlign='right-bottom'
          color='gray'
          illustration='/illustrations/main2/sectionAbout/figure_5.png'
        />
      }
    />
  );
}
