import {Image} from 'components/Image';
import {Card} from 'components/Main/Card';
import {MainContext} from 'components/Main/lib';
import {Typography} from 'components/Main/Typography';
import {buildMessage} from 'lib/intl';
import React, {useContext, useCallback} from 'react';
import {useIntl} from 'react-intl';
import styles from './index.module.scss';

type Props = {
  button: string;
  gridArea?: string;
  illustration: string;
  subtitle?: string;
  title: string;
};

export function Offer({gridArea, title, subtitle, button, illustration}: Props): React.ReactElement {
  const intl = useIntl();
  const {onOpenConsultationDialog} = useContext(MainContext);
  const handleOpenConsultationDialog = useCallback(() => {
    onOpenConsultationDialog?.();
  }, [onOpenConsultationDialog]);

  return (
    <Card color='black' padding={['s', 'm']}>
      <div className={styles.container}>
        <div className={styles.figure}>
          <Image className={styles.image} loading='lazy' src={illustration} />
        </div>
        <div className={styles.content}>
          <div className={styles.title}>
            <Typography kind='promo' variant='h4'>
              {buildMessage(intl, title)}
            </Typography>
          </div>
          {subtitle && (
            <div className={styles.subtitle}>
              <Typography kind='promo' size='l' variant='text'>
                {buildMessage(intl, subtitle)}
              </Typography>
            </div>
          )}
          <button className={styles.action} onClick={handleOpenConsultationDialog} type='button'>
            <Typography color='red' kind='promo' size='l' variant='text'>
              <span className={styles.arrow}>→</span>
              <span className={styles.text}>{buildMessage(intl, button)}</span>
            </Typography>
          </button>
        </div>
      </div>
    </Card>
  );
}
