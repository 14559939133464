import cn from 'classnames';
import {Image} from 'components/Image';
import {useAnalytics} from 'lib/analytics';
import {useAppEnv} from 'lib/appEnv';
import {isString} from 'lib/guards';
import React, {useCallback, useEffect, useState} from 'react';
import {Button} from 'uikit/Button';
import Plus from './icons/big-plus.svg';
import styles from './index.module.scss';

export type Step = {
  buttonText?: string;
  description: string;
  illustration: string | React.ReactElement;
  label: string;
  onButtonClick?: () => void;
};

export type Props = {
  forceStep?: number;
  steps: Step[];
  title: string;
};

export const GrowBusiness = ({steps, title, forceStep}: Props) => {
  const [openStep, setOpenStep] = useState(0);

  const analytics = useAnalytics();
  const appEnv = useAppEnv();

  const handleToggleClick = useCallback(
    (index: number) => {
      if (index === openStep) {
        analytics.sendEvent({
          payload: {
            pageUrl: window.location.href,
            position: index.toString(),
            source: 'growBusiness',
          },
          type: 'click',
        });
      }
      setOpenStep(index);
    },
    [steps, analytics.sendEvent, appEnv.currentPageName, openStep],
  );

  useEffect(() => {
    forceStep !== undefined && setOpenStep(forceStep);
  }, [forceStep]);

  const currentIllustration = steps[openStep].illustration;

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <span className={styles.title}>{title}</span>
        <div>
          {steps.map(({buttonText, description, label, onButtonClick, illustration}, index) => {
            const expanded = openStep === index;
            return (
              <div className={styles.step} key={index}>
                <button
                  className={cn(styles.toggleButton, {[styles.expanded]: expanded})}
                  onClick={() => {
                    handleToggleClick(index);
                  }}
                  type='button'
                >
                  <Plus className={styles.icon} />
                  <span className={styles.stepLabel}>{label}</span>
                </button>
                {expanded && (
                  <div className={styles.stepContent}>
                    <div className={cn(styles.imageBox, styles.mobile)}>
                      {isString(illustration) ? (
                        <Image className={styles.image} loading='lazy' src={illustration} />
                      ) : (
                        illustration
                      )}
                    </div>
                    <span className={styles.stepDescription}>{description}</span>
                    {onButtonClick && (
                      <div>
                        <Button color='outline-white' onClick={onButtonClick} type='button'>
                          {buttonText}
                        </Button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
      <div className={cn(styles.imageBox, styles.desktop)}>
        {isString(currentIllustration) ? (
          <Image className={styles.image} loading='lazy' src={currentIllustration} />
        ) : (
          currentIllustration
        )}
      </div>
    </div>
  );
};
