import React from 'react';
import {useIntl} from 'react-intl';
import {CardBenefit} from '../CardBenefit';
import {data} from './data';
import styles from './index.module.scss';

type Props = {
  gridArea?: string;
};

export function Benefits({gridArea}: Props): React.ReactElement {
  const intl = useIntl();

  return (
    <div className={styles.container} style={{gridArea}}>
      {data.map(({illustration, description, title, id}) => (
        <CardBenefit
          description={intl.formatMessage(description)}
          id={id}
          illustration={illustration}
          key={illustration}
          title={intl.formatMessage(title)}
        />
      ))}
    </div>
  );
}
