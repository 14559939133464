import {CardMedia} from 'components/Main/CardMedia';
import React from 'react';

export function CardFactory(): React.ReactElement {
  return (
    <CardMedia
      badge='✓ проверенный поставщик'
      badgeAlign='left-top'
      color='white'
      illustration='/illustrations/main2/sectionServices/factory.jpeg'
    />
  );
}
