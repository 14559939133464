import {HeaderCatalogButton} from 'components/Header/HeaderCatalogButton';
import {Card} from 'components/Main/Card';
import {HeaderSearchBar} from 'containers/HeaderContainer/HeaderSearchBar';
import {Category} from 'lib/category/types';
import React from 'react';
import {defineMessages, useIntl} from 'react-intl';
import styles from './index.module.scss';
import {formatShorthandNumber} from './utils';

const messages = defineMessages({
  highlight: {
    defaultMessage: 'wholesale purchasing and importing from China',
    description: 'Self service main page. Card with search bar: title (second part)',
  },
  subTitle: {
    defaultMessage:
      'More than {countOfProducts} products from {countOfSuppliers} suppliers in {countOfCategories} categories',
    description: 'Self service main page. Subtitle',
  },
  title: {
    defaultMessage: 'End-to-end platform for',
    description: 'Self service main page. Card with search bar: title (first part)',
  },
});

export type Props = {
  categories?: Category[];
  countOfCategories: number;
  countOfProducts: number;
  countOfSuppliers: number;
  isCatalogOpen?: boolean;
  onCatalogOpen?: () => void;
  openLinkInNewTab: boolean;
};

export const CardWithSearch = React.forwardRef<HTMLDivElement, Props>(function CardWithSearch(
  {onCatalogOpen, categories, countOfProducts, countOfSuppliers, countOfCategories, isCatalogOpen = false},
  actionRef,
): React.ReactElement {
  const intl = useIntl();

  return (
    <Card color='gray' padding='l' rounded='m'>
      <div className={styles.header}>
        <span>{intl.formatMessage(messages.title)}</span>
        <span>{intl.formatMessage(messages.highlight)}</span>
      </div>
      <div className={styles.subTitle}>
        {intl.formatMessage(messages.subTitle, {
          countOfCategories: formatShorthandNumber(countOfCategories, intl),
          countOfProducts: formatShorthandNumber(countOfProducts, intl),
          countOfSuppliers: formatShorthandNumber(countOfSuppliers, intl),
        })}
      </div>
      <div className={styles.actions} ref={actionRef}>
        {onCatalogOpen && categories && categories.length > 0 && (
          <div className={styles.catalog}>
            <HeaderCatalogButton expanded={isCatalogOpen} onClick={onCatalogOpen} shape='classic' />
          </div>
        )}
        <div className={styles.search}>
          <HeaderSearchBar shape='classic' theme='light' />
        </div>
      </div>
    </Card>
  );
});
