import {Carousel} from 'components/Carousel';
import {ProductLite} from 'components/ProductLite';
import {isContentListProductItem} from 'lib/contentList';
import {ContentList, ContentListProductItem} from 'lib/contentList/types';
import React, {useMemo} from 'react';
import styles from './index.module.scss';

export type Props = {
  items: ContentList;
  position: string;
  promotionId?: string;
  title?: string;
};

export function ProductsSelection({promotionId, title, position, items = []}: Props): React.ReactElement {
  const products = useMemo<ContentListProductItem[]>(() => {
    return items.filter((item): item is ContentListProductItem => {
      return isContentListProductItem(item);
    });
  }, [items]);

  return (
    <div className={styles.container}>
      <div className={styles.title}>{title}</div>
      <div className={styles.products}>
        <Carousel>
          {products.map((product, index) => (
            <div className={styles.product} key={product.id}>
              <ProductLite
                alwaysShowPrice
                index={index}
                noRating
                position={position}
                product={product.content}
                promotionId={promotionId}
              />
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
}
